.header__wrapper {
    position: relative;
}

.header-7 {
    display: flex;
    padding: 13px 0;
    align-items: center;
    background: #FFF;


    &__menu {
        $this: &;

        &-item {
            position: relative;

            &:hover {
                #{$this}-link {
                    color: var(--main);
                }

                #{$this}-submenu {
                    opacity: 1;
                    pointer-events: all;
                    transform: none;
                }

                &::before,
                &::after {
                    pointer-events: all;
                }
            }

            &::before,
            &::after {
                // content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 18px;
                pointer-events: none;
            }

            &::before {
                right: 100%;
            }

            &::after {
                left: 100%;
            }
        }

        &-submenu {
            position: absolute;
            top: 100%;
            left: -18px;
            padding: 16px 30px 17px 23px;
            min-width: 215px;
            transform: translateX(-6px);
            z-index: 1;
            background-color: #fff;
            opacity: 0;
            box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.04);
            border-radius: 0 0 $radius $radius;
            border: 1px solid $border;
            pointer-events: none;
            transition: opacity $tr-time, transform $tr-time;

            &-link {
                display: block;
                font-size: 14px;
                line-height: 1.3;

                &:not(:last-child) {
                    margin-bottom: 14px;
                }
            }
        }
    }

    &-logo {
        width: calcFluid(150, 222);
        height: 64px;
        //@include _medium {
        //  width: 150px;
        //}

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &-city {
        margin-left: calcFluid(20, 50, 0, 1199);
        flex-shrink: 0;

        &__icon {
            width: calcFluid(10, 20);
            height: calcFluid(10, 20);
        }

        &__link {
            span {
                border-bottom: 1px dashed #000;
                transition: .15s;

                &:hover {
                    transition: .3s;
                    border-bottom-color: transparent;
                }
            }
        }

        &__dropdown {
            width: 6px;
            height: 6px;
        }

        &-dropdown {
            padding-top: calcFluid(10, 20);

            &__wrapper {
                height: 100vh;
            }

            &__back {
                font-weight: 400;
                font-size: 16px;
                color: #000;
                display: flex;
                align-items: center;
                margin-bottom: calcFluid(12, 28);

                svg {
                    width: 6px;
                    height: 16px;
                    margin-right: 10px;
                }
            }

            &__title {
                font-weight: 900;
                font-size: calcFluid(22, 42);
                text-transform: uppercase;

                @include _medium {
                    margin-top: 15px;
                }
            }

            &-input {
                max-width: 550px;
                position: relative;

                input {
                    background: #FFFFFF;
                    border: 1px solid #787878;
                    border-radius: 8px;
                    font-weight: 400;
                    font-size: calcFluid(14, 18);
                    padding: calcFluid(14, 20) calcFluid(16, 40);
                    margin-top: calcFluid(18, 20);
                    width: 100%;

                    &::placeholder {
                        color: #555555;
                    }
                }

                &-items {
                    position: absolute;
                    left: 0;
                    right: 0;
                    background: #FFF;
                    margin-top: 50px;
                    padding: 0 20px 15px;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-row-gap: 18px;
                    max-height: 550px;
                    overflow-y: auto;
                }

                &-item {
                    font-size: 18px;
                    color: #000;

                    >span {
                        font-weight: bold;
                    }
                }

            }

            &-items {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                margin-top: calcFluid(16, 50);
                grid-gap: calcFluid(5, 50);
                overflow-y: auto;
                padding: 15px 0;

                @include _medium {
                    grid-template-columns: 1fr;
                }
            }

            &-group {
                @include _medium {
                    display: grid;
                    grid-template-columns: 1fr;
                    padding-left: 75px;
                    position: relative;
                }

                &__wrapper {
                    @include _medium {
                        min-height: 52px;
                    }

                }
            }

            &-item {
                width: fit-content;
                display: block;
                font-weight: 400;
                font-size: 16px;
                color: #000000;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                &.group_title {
                    width: calcFluid(35, 68);
                    height: calcFluid(35, 68);
                    background: #7B7B7B;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 900;
                    font-size: calcFluid(21, 44);
                    color: #FFF;
                    margin-bottom: 12px;

                    @include _medium {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
    }

    &-nav {
        padding-left: calcFluid(20, 67, 0, 1199);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-right: 5px;

        @include _Extra_large_ {
            padding-left: 15px;
        }

        &-item {
            display: flex;
            align-items: flex-start;
            margin: calcFluid(3, 17);

            &__icon {
                width: 16px;
                height: 16px;
                margin-right: calcFluid(0, 12, 0, 1199);
            }
        }

        &-dropdown {
            display: flex;
            flex-wrap: wrap;
            padding: 50px 0;

            &-items {
                display: block;
                width: calc(100% / 4 - 40px);
                margin: 20px;

                @include _Extra_large_ {
                    width: calc(100% / 4 - 60px);
                    margin: 30px;
                }
            }

            &-item {
                font-size: calcFluid(14, 16, 0, 1280, 1920)px;
                color: #000;
                display: block;
                padding: 9px 0;

                &.bold {
                    font-weight: 700;
                    font-size: calcFluid(16, 19, 0, 1280, 1920)px;
                }
            }
        }
    }

    &-contacts {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        align-items: center;
        flex-shrink: 0;
        font-size: calcFluid(16, 18, 0, 1199);
        width: fit-content;

        &__phone {
            font-weight: 700;
        }

        &__title {
            font-weight: 400;
            border-bottom: 1px dotted #333;
        }
    }

    &-socials {
        display: flex;
        margin: -10px;
        padding-left: calcFluid(15, 40, 0, 1199);

        &-item {
            margin: calcFluid(5, 10, 0, 1199);

            &__svg {
                width: 36px;
                height: 36px;

                &.i-youtube {
                    width: 42px;
                    height: 32px;
                }
            }

        }
    }

    &-search {
        margin-left: 20px;
        margin-right: 7px;

        &__icon {
            width: 34px;
            height: 34px;
            border-radius: 8px;
            border: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                width: 14px;
                height: 14px;
            }
        }

        &-dropdown {
            width: 100%;
            max-width: 1100px;
            margin: 40px auto 25px;
            display: flex;

            label {
                flex-grow: 1;
                position: relative;
            }

            &__wrapper {
                position: absolute;
                left: 0;
                right: 0;
                top: -1000px;
                background: #FFFFFF;
                border: 1px solid #BBBBBB;
                border-radius: 8px;
                padding-top: 20px;
                padding-bottom: 30px;
                transition: 1s;
                z-index: -1;

                &.active {
                    top: calc(100% + 4px);
                    transition: .5s;
                }
            }

            &-items {
                display: grid;
                grid-template-columns: 1fr;
                max-height: calc(46px * 7);
                overflow-y: auto;
            }

            &__item {
                padding: 13px 50px;
                transition: .3s;

                &:hover {
                    transition: .15s;
                    background: #FFF7AC;
                }
            }

            &-all_items {
                padding: 13px 50px;
                display: block;
                text-decoration: underline;
                transition: .15s;
                border: none;
                background: none;
                color: #4F4F4F;
                cursor: pointer;
                font-weight: 400;
                font-size: 18px;

                &:hover {
                    text-decoration: none;
                    transition: .3s;
                }
            }
        }

        &__input {
            background: #FFFFFF;
            border: 1px solid #000000;
            border-radius: 8px 0 0 8px;
            padding: 20px 40px;
            font-weight: 400;
            font-size: 18px;
            color: #000;
            width: 100%;
            height: 100%;
        }

        &__button {
            //margin-left: 10px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }

    &-dropdown {
        position: fixed;
        left: 0;
        right: 0;
        top: 90px;
        background: #FFF;
        transition: .3s;
        opacity: 0;
        border-top: 1px solid #BBB;

        &.active {
            opacity: 1;
            transition: .15s;
        }
    }
}

.header__logo {
    width: 60px;
}

.header-mobile-city {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    width: 100%;
    background: #000;

    .header-7-city {
        margin: 0;
        height: 28px;

        svg {
            fill: #FFF;
        }

        &__link {
            font-weight: 400;
            font-size: 12px;
            color: #FFF;

            span {
                border-color: #FFF;
            }
        }
    }
}

.header-mobile__contacts {
    font-weight: 700;
    font-size: 14px;
    color: #000;
    margin-left: 21px;
    text-transform: capitalize;
}

.header-city-mobile-container {
    top: 80px;
    left: 100%;
    opacity: 1;
    z-index: -10;
    transition: .5s;
    display: none;

    &.active {
        z-index: 2;
        left: 0;
        transition: .5s;
        display: block;
    }

    .header-7-city-dropdown-items {
        height: calc(100vh - 195px);
        padding-top: 0;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
    }

    .header-7-city-dropdown-group {
        height: fit-content;
    }

    .header-7-city-dropdown-input-items {
        flex-direction: column;
        display: none;
        top: 30px;
        min-height: calc(100vh - 200px);
        z-index: 30;

        &.active {
            display: flex;
        }
    }
}