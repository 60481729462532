@import 'base/mixins';


.product-bagdes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
}

.product-bagde {
  display: inline-block;
  padding: 4px 5px;
  color: #ffffff;
  line-height: 1;
  border-radius: 2px;

  &--discount {
    background: #ED6262;
  }

  &--new {
    background: var(--main);
  }

  &--hit {
    color: $text;
    background: var(--additional);
  }

  &--bottom-offset {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.product-card {
  min-width: 240px;
  max-width: 383px;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, .13);
  cursor: pointer;
  $this: &;
  --border: 1px solid #D0D0D0;
  --background-color: #EDEDED;
  --border-radius: 8px;
  border-radius: var(--border-radius);
  z-index: 1;
  display: flex;
  flex-direction: column;
  @include _medium {
    height: unset;
  }

  > object {
    height: 100%;
  }

  &:hover {
    z-index: 3;
    @include _medium {
      z-index: 2;
    }

    #{$this}__wrapper {
      border-bottom-color: transparent;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    #{$this}-footer {
      top: 100%;
      transition: top .3s;
      border-top-color: transparent;
      @include _medium {
        top: 50%;
      }
    }
  }

  &__wrapper {
    background: var(--background-color);
    border: var(--border);
    border-radius: var(--border-radius);
    z-index: 2;
    position: relative;
    height: 100%;
  }

  &-header {
    background: #FFFFFF;
    border-bottom: 1px solid #D0D0D0;
    height: calcFluid(190, 240);
    border-radius: var(--border-radius);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    //padding-bottom: 10px;

    &-img {
      padding-right: 145px;
    }
  }

  &-images {
    overflow: hidden;
    height: 100%;
    border-radius: 8px 8px 0 0;
    position: relative;

    .swiper-slide {
      padding-bottom: 18px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      //padding-bottom: calcFluid(5, 15);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__pagination {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      display: flex;
      justify-content: center;
      margin: -3px;

      .pagination-bullet {
        display: block;
        width: 22px;
        height: 3px;
        margin: 3px;
        background-color: #D0D0D0;
        transition: .3s;

        &.active {
          background-color: $primary;
          transition: .15s;
        }
      }
    }
  }

  &-body {
    padding: calcFluid(14, 16) calcFluid(14, 22) calcFluid(14, 22);
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
  }

  &-attrs {
    display: flex;
    flex-direction: column;
    margin: -5px;
  }

  &-attr {
    margin: 5px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 5px;


    &__name, &__value {
      font-weight: 400;
      font-size: calcFluid(12, 14);
      display: flex;
      align-items: center;
    }

    &__name {
      text-align: left;

      svg {
        width: calcFluid(20, 30);
        height: calcFluid(20, 30);
        margin-right: 10px;
      }
    }

    &__value {
      text-align: right;
    }
  }

  &-footer {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border: var(--border);
    background: var(--background-color);
    border-radius: var(--border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
    transition: .15s;
    padding: 3px 15px 15px;

    .ps-btn {
      width: 100%;
      min-width: auto;
    }
  }

  &__title {
    color: #000;
    font-weight: 700;
    font-size: calcFluid(14, 18);
  }
}