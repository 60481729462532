.btn {
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  border: 0;
  border-radius: $radius;
  cursor: pointer;
  transition: color $tr-time, border-color $tr-time, background-color $tr-time, box-shadow $tr-time;

  &--filled {

    color: #fff;
    background: var(--main);
    border: 1px solid var(--main);

    &:hover {
      color: #fff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    &:active, &:focus {
      background: var(--main-darker);
      border-color: var(--main-darker);
    }

    &_disable {

      color: #929292;
      background: #EAEAEA;
      border-color: transparent;

    }

  }

  &--filled-2 {
    color: var(--main);
    border: 1px solid var(--main);

    &:hover {
      color: var(--main-darker);
      border-color: var(--main-darker);
    }

    &:active {
      color: #186F67;
      border-color: #186F67;
    }

    &:focus {
      background-color: var(--main-lighter);
    }

    &_disable {

      color: #B6B6B6;
      border-color: #B6B6B6;

    }
  }

  &--filled-3 {

    color: #333333;
    border: 1px solid #333333;

    &:hover {
      color: var(--main);
      border-color: var(--main);
    }

    &:active {
      color: var(--main-darker);
      border-color: var(--main-darker);
    }

    &:focus {

      background-color: var(--main-lighter);

    }

    &_disable {

      color: #B6B6B6;
      border-color: #B6B6B6;

    }

  }

  &--empty {
    color: #929292;
    border-bottom: 1px dashed #B6B6B6;
  }

  &--white {
    color: var(--main);
    background: #ffffff;
  }

  &--auto-width {
    padding: 0 24px;
  }

  &--default-height {
    height: 42px;
  }

  &--clarify {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    width: 185px;
    height: 40px;

    top: 0;
    bottom: 0;
    margin: auto;
    right: 40px;
    font-weight: 600;

    border-radius: 5px;
  }

}

.ps-btn {
  font-weight: 700;
  font-size: calcFluid(14, 18);
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid;
  padding: calcFluid(10, 20) calcFluid(30, 60);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  cursor: pointer;
  width: fit-content;
  text-align: center;
  @include _Extra_large_ {
    padding-left: 15px;
    padding-right: 15px;
    min-width: 290px;
  }

  svg {
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }

  &-primary {
    background: $primary;
    border-color: $primary;

    &:hover {
      background: $dark-primary;
      transition: .15s;
    }

    &:focus {
      border-color: $blue;
      transition: .15s;
    }
  }

  &-secondary {
    border-color: #000;
    background: #fff;

    &:hover {
      background: $primary;
      border-color: $primary;
      transition: .15s;
    }

    &:focus {
      border-color: $blue;
      transition: .15s;
    }
  }
}


.ps-input {
  font-size: calcFluid(14, 18);
  // color: #E3E3E3;
  color: #000;
  border: 1px solid #BBBBBB;
  border-radius: 8px;
  padding: calcFluid(10, 23) calcFluid(15, 30);
  // background: none;
  max-width: 360px;
  width: 100%;

  &.edits {
    height: 100%;
  }

  &-label {
    font-size: 12px;
    color: $primary;

    padding-bottom: 10px;

  }
}
