.footer-5 {
  background: #F5F5F5;
  border-top: 1px solid #D9D9D9;

  &-top {
    margin-top: calcFluid(15, 50);

    &__wrapper {
      display: flex;
      padding-top: calcFluid(11, 50);
      padding-bottom: calcFluid(10, 70);
      @include _medium {
        flex-direction: column;
      }
    }

    &-nav {
      flex-grow: 1;
      display: flex;
      margin: -20px;
      flex-wrap: wrap;
      @include _mobile{
        margin: -15px;
      }
      @include _medium {
        margin: 0;
        justify-content: center;
      }

      &__group {
        display: flex;
        flex-direction: column;
        margin: 20px;
        width: 255px;
        @include _mobile {
          margin: 15px;
        }
        @include _medium {
          margin: 11px;
          text-align: center;
        }
      }

      &__link {
        height: fit-content;
        font-size: calcFluid(12, 16);

        &:not(:last-child) {
          margin-bottom: calcFluid(13, 16);
        }

        &.bold {
          font-weight: bold;
          font-size: calcFluid(14, 16);
        }
      }
    }

    &-contacts {
      text-align: right;
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      @include _medium {
        align-items: center;
        text-align: left;
        padding-left: 10px;
        margin-top: 34px;
      }

      &-phone {
        font-size: calcFluid(14, 18);
        text-align: right;
        color: #000;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        min-width: 175px;
        @include _medium {
          align-items: center;
        }
      }

      &__call {
        border-bottom: 1px dotted #000;
        font-weight: normal;
        width: fit-content;
        @include _medium {
          margin-top: 10px;
        }
      }
    }

    &-socials {
      padding-top: calcFluid(15, 20);
      margin: -10px;
      display: flex;
      min-width: 145px;
      justify-content: flex-end;
      align-items: center;
      @include _medium {
        justify-content: center;
        align-items: center;
      }

      &-item {
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .i-whatsapp {
        width: 36px;
        height: 36px;
      }

      .i-youtube {
        width: 42px;
        height: 28px;
      }
    }

    &-search {
      margin-top: 20px;
      @include _medium {
        display: none;
      }
    }
  }

  &-bottom {
    border-top: 1px solid #D9D9D9;
    padding: 20px 0;
    font-size: 16px;
    color: #000;
    opacity: .7;
    @include _small {
      margin-top: 34px;
      padding: 34px 0;
    }

    &__copyright {
      font-size: inherit;
      color: inherit;
    }

    &__wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      @include _medium {
        flex-direction: column;
      }
      @include _small {
        align-items: center;
      }
    }

    &-links {
      padding-left: 120px;
      display: flex;
      margin: -5px;
      @include _medium {
        padding: 10px 0;
        flex-direction: column;
      }
    }

    &__link {
      margin: 5px;
      text-decoration: underline;
      transition: .3s;

      &:hover {
        transition: .15s;
        text-decoration: none;
      }
    }

    &__ps {
      margin-left: auto;
      font-size: 16px;
      color: #000;
      @include _medium {
        margin-left: 0;
      }

      &-icon {
        height: 23px;
        width: 113px;
      }
    }
  }
}