@import "./components/index-blog";
@import "./components/page-title";
@import "./components/index-block";
@import "./components/about-footer";

.section-qa {
  &__wrapper {
    margin-top: calcFluid(10, 80);
    padding-bottom: 15px;
    padding-top: 25px;
  }

  &-items {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }

  &-item {
    $item: &;
    display: flex;
    flex-direction: column;
    user-select: none;
    margin: 12px 0;

    &.active {
      #{$item}__question {
        svg {
          transform: rotate(-90deg);
          transition: .15s;
        }
      }

      #{$item}__answer {
        display: block;
      }
    }

    &__question {
      border: 1px solid #C9C9C9;
      border-radius: 8px;
      padding: calcFluid(11, 20) calcFluid(20, 40);
      font-weight: 700;
      font-size: calcFluid(12, 24);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: .3s;

      svg {
        width: calcFluid(12, 24);
        height: calcFluid(12, 24);
        fill: #000;
        transform: rotate(90deg);
        transition: .3s;
      }

      &:hover svg {
        fill: $primary;
        transition: .15s;
      }
    }

    &__answer {
      padding: calcFluid(20/4, 20) calcFluid(40/4, 40);
      font-weight: 400;
      font-size: calcFluid(12, 18);
      color: #000;
      display: none;
    }
  }
}

.textblock_component {
  $this: &;


  &-section {
    &:nth-child(3){
      .content-text{
        h2{
          margin-top: 0;
        }
      }
    }
    &[data-skip-divider] {
      padding: 72px 0;
      background: #F5F5F5;
    }
    &__footer {
      & .section-about-footer__wrapper {
        width: fit-content;
        @include _mobile {
          display: flex;
          justify-content: center;
        }
      }
       & .section-about-footer__text {
        @include _mobile {
          text-align: center;
          width: 100%;
        }
      }
      & .section-about-footer__img {
        margin: 0 0 0 33px;
        @include _mobile {
          margin: 0;
        }

      }
    }
    &.bg {
      background: #F5F5F5;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    @include _medium {
      flex-direction: column;
      #{$this}-image {
        margin-bottom: 10px;
      }
    }

    &.right {
      flex-direction: row-reverse;
      @include _medium {
        flex-direction: column-reverse;
      }
    }

    &.left {
      #{$this}-image {
        margin-right: 60px;
        margin-left: 0;
        @include _medium {
          margin-right: 0;
        }
      }
    }
  }

  &-image {
    margin-top: 10px;
    width: calc(50% - 60px);
    flex-shrink: 0;
    margin-left: 60px;
    @include _medium {
      margin-left: 0;
      width: 100%;
    }

    iframe {
      width: 100%;
      height: 100%;
      min-height: 415px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 14px;
    }
  }

  .content-text {
    margin-top: 10px;
  }
}

.content-text {
  max-width: 1072px;
}

.points__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: calcFluid(15, 40) calcFluid(10, 80);
  margin-top: $divider;
  margin-bottom: $divider;
}

.points-item {
  display: flex;
  align-items: center;
  width: 100%;
  @include _container {
    flex-direction: column;
    align-items: flex-start;
  }

  svg {
    width: calcFluid(44, 55);
    height: calcFluid(44, 55);
    fill: #FFAE00;
    margin-right: calcFluid(20, 40);
  }

  &__title {
    font-size: calcFluid(14, 20);
    @include _container {
      margin-top: 10px;
    }
  }
}

.products_component {

  &-body {
    display: flex;
    @include _medium {
      flex-direction: column-reverse;
    }
  }

  &-sidebar {
    width: 100%;
    max-width: 385px;
    flex-shrink: 1;
    margin-left: 28px;
    @include _container {
      margin-left: 0;
      margin-bottom: 15px;
    }
    @include _medium {
      margin: 0 -15px;
      width: calc(100% + 30px);
    }
  }

  &__title {
    letter-spacing: .02em;
    text-transform: uppercase;
    font-weight: 900;
    font-size: calcFluid(24, 54);
    margin-bottom: calcFluid(18, 40);
    @include _medium {
      text-align: center;
    }
  }

  &-items {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    height: fit-content;
    padding-right: 20px;

    &.fullwidth {
      grid-template-columns: repeat(4, 1fr);
    }

    .product-card {
      @include _medium {
        max-width: 320px;
        margin: 0 auto;
      }
    }

    &__wrapper {
      flex-grow: 1;
    }

    img {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }

    @include _mobile {
      grid-template-columns: repeat(2, 1fr) !important;
    }
    @include _medium {
      grid-template-columns: 1fr !important;
      display: grid;
      overflow-y: auto;
      padding: 18px 15px;
      margin: 0 -15px;
      width: calc(100% + 30px);

    }

    &.grid-4 {
      grid-template-columns: repeat(4, 1fr);
      @include _mobile {
        grid-template-columns: repeat(3, 1fr);
      }
      @include _medium {
        grid-template-columns: repeat(2, 1fr);
      }
      @include _small {
        grid-template-columns: 1fr;
      }
    }
  }

  &-categories {
    background: #F3F3F3;
    border-radius: 8px;
    --h_padding: 22px;
    padding: 32px var(--h_padding) 32px 0;
    @include _medium {
      --h_padding: 18px;
    }

    .active {
      border-left: 8px solid $primary;
      @include _medium {
        border-left: none;
      }
    }

    &__title {

      padding-left: var(--h_padding);
      display: flex;
      align-items: center;
      justify-content: space-between;

      span:first-child {
        font-weight: 700;
        font-size: calcFluid(18, 24);
        text-decoration: underline;
        @include _medium {
          text-align: center;
        }
      }

      span.back {
        margin-left: 5px;
        font-weight: 400;
        font-size: 18px;
        display: flex;
        flex-shrink: 0;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }

    &-items {
      border-top: 1px solid #AAA;
      padding-top: 20px;
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      @include _medium {
        border-top: none;
      }
    }

    &-item {
      font-weight: 700;
      font-size: calcFluid(14, 18);
      color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: var(--h_padding);

      &.active {
        span {
          color: #AAA;
        }

        svg {
          fill: #AAA;
        }
      }

      svg {
        width: calcFluid(12, 24);
        height: calcFluid(12, 24);
        transform: rotate(180deg);
      }
    }
  }

  &-filter, &-feedback {
    margin-top: calcFluid(18, 33);
    padding: calcFluid(18, 35) calcFluid(15, 32);
    background: #F3F3F3;
    border-radius: 8px;
  }

  &-feedback {
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 10px;
    background: #000;
    color: #fff;

    &__title {
      text-align: center;
      font-weight: 700;
      font-size: calcFluid(18, 24);
    }

    &__subtitle {
      text-align: center;
      font-size: calcFluid(14, 16);
    }

    input {
      color: #fff;
    }

    .ps-btn {
      width: 100%;
    }

    &__footer {
      text-align: center;
      font-size: calcFluid(14, 18);

      a {
        text-decoration: underline;
        transition: .3s;
        color: #fff;

        &:hover {
          text-decoration: none;
          transition: .15s;
        }
      }
    }
  }


  &-filter {


    &__title {
      font-weight: 700;
      font-size: calcFluid(18, 24);
      color: #000;
      margin-top: 17px;
      margin-bottom: 17px;
      @include _medium {
        text-align: center;
        text-decoration: underline;
      }
    }

    &__form {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 12px;

    }

    &-item {
      $item: &;

      &__label {
        width: 100%;
        display: block;
        position: relative;

        &.dropdown {
          #{$item}__input {
            cursor: pointer;
          }

          &.active {
            #{$item}__input {
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
            }

            #{$item}__dropdown {
              border-top-color: transparent;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              display: grid;
            }
          }
        }
      }

      &__input {
        background: #FFFFFF;
        border: 1px solid #AAAAAA;
        border-radius: 8px;
        padding: calcFluid(15, 20) calcFluid(22, 30);
        font-size: calcFluid(12, 18);
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include _medium {
          background: none;
        }

        svg {
          transform: rotate(-90deg);
          width: calcFluid(12, 24);
          height: calcFluid(12, 24);
          margin-left: 10px;
          flex-shrink: 0;
        }

        &::placeholder {
          color: #AAAAAA;
        }
      }

      &__dropdown {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 2;
        background: #FFF;
        padding: calcFluid(15, 22) 22px;
        display: none;
        grid-template-columns: 1fr;
        grid-row-gap: 15px;
        border: 1px solid #aaa;
        border-radius: 8px;

        a {
          font-size: calcFluid(12, 16);
        }
      }

      color: #000;
    }

    &__btn {
      min-width: 275px;
      max-width: 100%;
      width: 100%;
      @include _medium {
        margin-right: auto;
        margin-left: auto;
        font-size: 14px;
      }
    }
  }

}

.section-divider-component {
  max-height: var(--max_height);
  min-height: var(--min_height);
  height: var(--max_height);
  @include _medium {
    height: var(--min_height);
  }
}

.section-points {
  $this: &;

  .page__description {
    @include _medium {
      display: none;
    }
  }

  &.cards {
    #{$this}-item {
      border: 1px solid #C9C9C9;
      border-radius: 8px;
      padding: calcFluid(0, 20);
      @include _medium {
        border: none;
      }

      &-body {
        font-weight: bold;
      }
    }
  }

  &.grid-5 {
    #{$this}-items {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;
      @include _container {
        grid-template-columns: repeat(4, 1fr);
      }
      @include _large {
        grid-template-columns: repeat(3, 1fr);
      }
      @include _medium {
        grid-template-columns: repeat(2, 1fr);
      }
      //@include _small {
      //  grid-template-columns: 1fr;
      //}
    }
  }

  &.grid-4 {
    #{$this}-items {
      grid-template-columns: repeat(4, 1fr);
      @include _container {
        grid-template-columns: repeat(3, 1fr);
      }
      @include _medium {
        grid-template-columns: repeat(2, 1fr);
      }
      //@include _small {
      //  grid-template-columns: 1fr;
      //}
    }
  }

  &.grid-3 {
    #{$this}-items {
      grid-template-columns: repeat(3, 1fr);
      @include _medium {
        grid-template-columns: repeat(2, 1fr);
      }
      @include _small {
        grid-template-columns: 1fr;
        #{$this}-item {
          text-align: center;

          &-header {
            align-items: center;
          }
        }
      }
    }
  }

  &.inside {
    #{$this}__wrapper {
      padding: calcFluid(0, 60) calcFluid(0, 60);
      background: #F3F3F3;
      border-radius: 8px;
      @include _medium {
        background: transparent;
      }
    }

    #{$this}-items {
      grid-column-gap: calcFluid(60/4, 60);
    }

    .page__title {
      letter-spacing: 0;
    }
  }

  &-items {
    margin-top: calcFluid(18, 50);
    display: grid;
    grid-gap: calcFluid(14, 40) calcFluid(40, 120);
  }

  &-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: calcFluid(5, 15);

    &-header {
      display: flex;
      align-items: center;
      @include _medium {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    &__icon {
      width: calcFluid(44, 55);
      height: calcFluid(44, 55);
      fill: $secondary;
      margin-right: 20px;
      flex-shrink: 0;
      @include _medium {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    &__title {
      font-weight: 700;
      font-size: calcFluid(14, 18);
      color: #000;
    }

    &__subtitle {
      margin-top: 5px;
      font-size: calcFluid(12, 18);
    }

    &-body {
      font-weight: 400;
      font-size: calcFluid(14, 18);
    }
  }
}

.section-attrs {
  $this: &;


  &-video {
    margin-left: 30px;
    padding: calcFluid(10, 35) calcFluid(15, 40);
    border: 1px solid #C9C9C9;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include _mobile {
      margin-left: 0;
      width: 100%;
      margin-bottom: 5px;
    }
    @include _medium {
      padding: 0;
      border: none;
      margin: 0 -15px;
      max-width: calc(100% + 30px);
    }


    &.only {
      text-align: center;
      max-width: 50%;
      margin: 0 auto;
      @include _medium {
        padding: 0;
        border: none;
        max-width: calc(100% + 30px);
      }
    }

    &__title {
      font-weight: 900;
      font-size: calcFluid(14, 24);
      margin-bottom: calcFluid(0, 20);
      @include _medium {
        order: 2;
        text-align: center;
        margin-top: 14px;
      }
    }

    &-body {
      width: 100%;
      height: calcFluid(180, 415);
      @include _medium {
        order: 1;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    &__btn {
      width: 100%;
      margin-top: calcFluid(18, 30);
      @include _medium {
        order: 3;
        max-width: 240px;
        margin: 18px auto;
      }
    }
  }

  &-group {
    --border: 1px solid #AAAAAA;
    margin-top: calcFluid(15, 40);
    flex-grow: 1;
    width: 100%;

    &.col-1 {
      #{$this}-table__row:not(:last-child) #{$this}-table-item {
        border-bottom: none;
      }
    }

    &.col-2 {
      //Предпоследний и последний объект
      #{$this}-table__row:not(:nth-last-child(-n+2)) #{$this}-table-item {
        border-bottom: none;
      }

      #{$this}-table__row:nth-child(even) #{$this}-table-item__title {
        @include _mobile {
          border-left: var(--border);
        }
      }

      #{$this}-table {
        width: 50%;
        margin-top: 0px;
        &:last-child {
          margin-left: -1px;
          width: calc(50% + 1px);
          @include _mobile {
            width: 100%;
            margin-left: unset;
          }
        }
        @include _mobile {
          width: 100%;
          margin-top: -1px;
        }
      }

      #{$this}-table__row {
        & #{$this}-table-item {
          @include _mobile {
            min-height: unset;
            margin-top: 1px;
          }

        }
        &:last-child {
          margin-top: -1px;
        }
        @include _mobile {
          width: 100%;
          margin-bottom: -1px;
        }
      }

      .empty {
        @include _container {
          display: none;
        }
      }
    }

    &__title {
      font-weight: 900;

      font-size: calcFluid(16, 24);
      color: #000;
      border: 1px solid #aAA;
      margin-bottom: -1px;
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include _medium {
        margin-top: 14px;
        text-align: center;
      }
    }

    &-body {
      display: flex;
      flex-wrap: wrap;
      @include _mobile {
        flex-direction: column-reverse;
        align-items: center;
      }

      &__wrapper {
        &.video {
          width: 50%;
          @include _mobile {
            width: 100%;
          }
        }
        display: grid;
        grid-template-rows: auto 1fr;
        @include _mobile {
          width: 100%;
        }
      }
    }
  }

  &-table {
    flex-direction: column;
    display: grid;

    &__row {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;

    }

    &-item {
      width: 50%;
      border: 1px solid #AAAAAA;
      padding: calcFluid(10, 20) calcFluid(12, 32);
      font-weight: 400;
      font-size: calcFluid(12, 18);

      &__title {
        border-right: none;
        background: #F3F3F3;
      }
    }
  }
}

