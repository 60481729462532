.section-page-title {
  background-color: #F5F5F5;
  padding: calcFluid(24, 60) 0;

  &__description {
    margin-top: calcFluid(18, 26);
    font-size: calcFluid(12, 18);
    line-height: calcFluid(15, 26);
    color: #000;
  }
  &__wrapper {
    max-width: 1072px;
  }
}