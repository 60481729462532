.section-blog {
  margin-top: calcFluid(10, 70);

  &__wrapper {
    overflow-x: auto;
  }

  &-items {
    padding-top: calcFluid(10, 25);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: calcFluid(15, 30);
    grid-auto-flow: dense;
    @include _mobile {
      grid-template-columns: repeat(3, 1fr);
    }
    @include _medium {
      grid-template-columns: repeat(2, 1fr);
      display: flex;
    }
    @include _small {
      grid-template-columns: 1fr;
    }
  }

  &-item {
    display: block;
    background: #FFFFFF;
    &:not(:last-child) {
      margin-right: calcFluid(15, 30);
    }
    border: 1px solid #D9D9D9;
    --border-radius: 14px;
    border-radius: var(--border-radius);
    max-width: 380px;
    min-height: 300px;
    transition: .15s;
    @include _medium {
      width: 240px;
      flex-shrink: 0;
      min-height: 0;
    }

    &.video {
      border: none;
      grid-column: 1 / 2 span;
      max-width: 100%;
      width: 100%;

      @include _small {
        grid-column: auto;
      }

      iframe {
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius);
      }
    }

    &:not(.video):hover {
      box-shadow: 0 3px 14px 2px rgba(0, 0, 0, .13);
      transform: translateY(-20px);
      transition: .3s;
      @include _medium {
        transform: none;
        box-shadow: none;
      }
    }

    &-header {
      height: calcFluid(225, 300, 0, 1280, 1920);
      display: block;
      @include _mobile {
        height: auto;
      }
      img {
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        @include _medium {
          height: 205px;

        }
      }
    }

    &-body {
      padding: calcFluid(12, 28) calcFluid(0, 20) 20px;
      text-align: center;
      display: block;
    }

    &__title {
      font-weight: 900;
      font-size: calcFluid(14, 18);
      color: #000;
    }
  }
}