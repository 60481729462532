.index-categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: #{$gap*2};
  column-gap: #{$gap*2};

  &__link {
    width: 192px;
    margin: calcFluid(20, 40) auto 0;
  }

  &__show-all {

  }

  &__to-all {
    margin-top: 17px;
  }

  &.show-all {

    &::before {
      display: none;
    }

  }
}

@media (max-width: 1199px) {
  .index-categories {
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;

    &__to-all {
      display: none;
    }
  }
}

@media (min-width: 576px) {
  .index-categories {
    &__show-all {
      display: none;
    }
  }
}

@media (max-width: 575px) {
  .index-categories {
    grid-template-columns: 1fr;

    &:not(.show-all) {
      .category-block-2:nth-child(n+5) {
        display: none;
      }

      .category-block-3:nth-child(n+5) {
        display: none;
      }
    }
  }
}

.section-categories {

  &-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: calcFluid(15, 30);
    height: 730px;
    margin-top: calcFluid(20, 30);
    @include _mobile {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      height: auto;
    }
    @include _medium {
      grid-template-columns: 1fr;
    }
  }

  &-item {
    border: 1px solid #9B9B9B;
    border-radius: 8px;
    padding: calcFluid(28, 36) calcFluid(5, 50);
    font-size: calcFluid(14, 32);
    font-weight: 700;
    color: #010101;
    background-color: #FFFFFF;
    background-position: right 30px bottom;
    background-repeat: no-repeat;
    background-size: 300px;
    background-image: var(--outline);
    transition: .15s;

    &:hover {
      background-image: var(--image);
      transition: .3s;
      border-color: #000;
    }


    &:nth-child(1) {
      grid-row-start: 1;
      grid-row-end: 3;
      background-size: calcFluid(330, 460, 0, 1280, 1920);
      @include _mobile {
        grid-row-start: auto;
        grid-row-end: auto;
        background-size: 155px;
      }
    }

    &:nth-child(2) {
      grid-column-start: 2;
      grid-column-end: 4;
      background-size: 550px;
      @include _mobile {
        grid-column-start: auto;
        grid-column-end: auto;
        background-size: 300px;
      }
      @include _medium {
        background-size: clamp(265px, 50%, 300px);
      }
    }

    &:nth-child(3) {
      @include _mobile {
        height: 248px;
      }
    }

    &:nth-child(4) {
      @include _mobile {
        height: 299px;
      }
    }

    @include _mobile {
      height: 280px !important;
      background-position: bottom 10px center;
      text-align: center;
      background-size: 235px;
    }
  }
}
