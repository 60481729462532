@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import "/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "/src/scss/vendors/toastr.min.css";

body {
  min-height: 100vh;
  height: 100%;
  margin: 0;
  color: $text;
  font-family: 'Inter';
  zoom: .9;
  @include _mobile {
    zoom: .6;
  }
}


.divider {
  margin-top: calcFluid(40, 80);
}

.d-none {
  display: none !important;
}

main {
  position: relative;
  // padding-top: 20px;
}

p, ol, ul, h1, h2, h3, button, form {
  margin: 0;
  padding: 0;
}

button {
  font-family: inherit;

  &:focus {
    outline: none;
  }
}

h1, h2 {
  font-weight: normal;
}

button, input, textarea {
  font-family: inherit;

  &:focus {
    outline: none;
  }
}

input {
  min-width: 0;
  font-size: inherit;
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

a {
  color: $text;
  text-decoration: none;
  transition: color $tr-time;
  // &:hover{
  // 	color: var(--main);
  // }
}

svg {
  vertical-align: middle;
  transition: fill $tr-time;
}

picture {
  display: block;
}

img {
  display: block;
}

source {
  display: none;
}

*, ::before, ::after {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

.swiper-pagination-bullet:only-child {
  visibility: hidden;
}

.swiper-button-disabled {
  // display: none !important;
  opacity: .3;
  pointer-events: none;
  user-select: none;
}

.d-flex {
  display: flex;
}

.d-iflex {
  display: inline-flex;
}

.ai-center {
  align-items: center;
}

.ai-flex-end {
  align-items: flex-end;
}

.ai-flex-start {
  align-items: flex-start;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-flex-end {
  justify-content: flex-end;
}

.fw-wrap {
  flex-wrap: wrap;
}

.fl-auto {
  flex: 0 0 auto;
}

.fl-grow {
  flex-grow: 1;
}

.fl-full {
  flex: 1 1 100%;
}

.fd-column {
  flex-direction: column;
}

.as-stretch {
  align-self: stretch;
}

.hidden {
  display: none !important;
}

.overflow {
  &-auto {
    overflow: auto;
  }

  &-hidden {
    overflow: hidden;
  }
}

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  user-select: none;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.lazy-load {
  &.loading {
    opacity: 0;
  }
}

.adaptive-img {
  position: relative;

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page {

  position: relative;


  &-link {
    display: flex;
    // position: absolute;
    z-index: 1;
    flex-direction: column;
  }

}

.load * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

@media (max-width: 1199px) {
  .container {
    max-width: 100%;
  }
}

