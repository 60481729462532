.article-filter{

    &__title{

        font-style: normal;
        font-weight: 500;

        font-size: 14px;
        line-height: 24px;

        color: #929292;

        margin-bottom: 18px;

    }

    &__list{

        &_type{

            display: flex;
            flex-wrap: wrap;
            margin-left: -8.5px;

        }

        &_color{

            display: flex;
            flex-wrap: wrap;

            width: 100%;
            max-width: 420px;

            margin-left: -5.5px;

        }

    }

    &__item{

        &-type{

            margin: 0 8.5px;
            margin-bottom: 15px;

        }

        &-color{

            margin: 0 5.5px;
            margin-bottom: 11px;

        }


    }

}

.article-block-price{

    height: fit-content;

    &__wrapper{

        display: flex;
        align-items: center;

        & > * {

            &:first-child{

                margin-right: 15px;
    
            }

        }

    }

}

.article-description{

    display: flex;

}

.inactive{
    visibility: hidden;
}
.display-none{
    display: none;
}
.article-tab{

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    // width: 220px;
    height: 60px;

    border: 1px solid transparent;
    border-bottom: none;
    border-radius: 4px 4px 0px 0px;

    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    
    &_active{

        border: 1px solid #EAEAEA;
        
        @include _medium_ {

            border-bottom: none;
            
        }
        
        color: var(--main);

    }

    

    &_feature{

        width: calc(100%/4);

        @include _medium {

            width: 100%;
            
        }

    }

}

.article-table{

    $this:&;

    background: #FFFFFF;

    border: 1px solid #EAEAEA;
    border-radius: 4px;
    
    width: fit-content;

    @include _small {
        
        width: 100vw;
        overflow: overlay;
        
    }

    &_mod{

        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: inherit;

    }

    &_mod-2{  

        @include _large {
        
            width: 100vw !important;
            overflow: overlay;
            
        }

        #{$this}{

            &__table{

                min-width: 1000px;

            }

            &__column{

                &:first-child{
                
                    border-left: none;
                    
                }
                
                border-left: 1px solid #EAEAEA;

                padding-left: 24px;

            }
        
        }

    }

    &__table{

        min-width: 500px;

        border-spacing: 0;
        width: 100%;

        &_2{

            #{$this}{
        
                &__column{
        
                    &:last-child{
        
                        padding-right: 40px;
            
                    }
        
                }
            
            }

        }

    }

    &__body{



    }

    &__row{

        td{
            border-bottom: 1px solid #EAEAEA;
        }


        &_result{


            #{$this}{
        
                &__column{
        
                    &:last-child{
        
                        padding-right: 40px;
            
                    }
        
                }
            
            }

        }

        &_header{

            background: #F8F8F8;

            td{

                padding: 12px 0;
                color: #B6B6B6;

            }

        }
        &--variability{
            position: relative;
        }
    }

    &__column{

        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;

        padding: 20px 0;

        &:not(:last-child){
            padding-right: 30px;
        }

        &:first-child{
 
            padding-left: 24px;

        }

        &:last-child{

            padding-right: 18px;

        }

        &_flex{

            display: flex;
            justify-content: space-between;

        }

        &_point{

            &.plus{

                color: var(--main);

            }


            &.minus{

                color: #ED6262;

            }

        }

    }

    &__cart{

        display: flex;
        align-items: center;
        justify-content: center;

        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;

        cursor: pointer;

        width: 60px;
        height: 42px;

        border-radius: 4px;

        color: #fff;

        background-color: var(--main);

        &-svg{

            width: 24px;
            height: 24px;

            fill: #fff;
            

        }

    }

    &__counter{

        width: 90px;
        height: 42px;

    }

    &__price{

        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 24px;

        &-old{

            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 12px;

            text-decoration-line: line-through;

            color: #B6B6B6;

            & + span{

                color: var(--main);

            }

        }

        &-wrap{

            display: flex;
            flex-direction: column;

            justify-content: center;

            margin-right: 15px;
            flex-shrink: 0;

        }

    }
    @media (max-width: 767px) {
        &__mobile-scroll{
        overflow: scroll;
    }
    }
    

    

}

.article-result{

    display: flex;
    justify-content: space-between;

    @include _small {
        
        flex-direction: column;
        padding-right: 24px !important;
        
    }

    :first-child{

        margin-right: 12px;

    }

    &__manage{

        display: flex;

        &-btn{

            height: 42px;
            
            &_buy{

                width: 137px;

            }

            &_cart{
                min-width: 100px;
                // width: 180px;

                color: var(--main) !important;
                border: 1px solid var(--main);
                background-color: #fff;

            }

        }

    }

    &__price{

        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 44px;

        display: flex;
        align-items: center;

        color: var(--main);

        &-wrap{

            display: flex;
    
            align-items: center;
    
            width: fit-content;
    
        }
    
        &-title{
            margin-right: 15px;
        }

    }

}

.article-plus{

    padding: 17px 30px 30px 23px;
    background-color: #F8F8F8;

    &__title{

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        margin-bottom: 8px;

    }

    &__list{

        margin-bottom: 0px !important;

        &-item{

            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;

            margin-bottom: 3px !important;

        }

    }

    &__link{

        color: var(--main);

    }

}

.article-receive{

    display: flex;

    &__item{

        &:first-child{

            margin-right: 50px;

        }

        & > * {

            margin-bottom: 8px;

            &:nth-last-child(2){
                margin-bottom: 5px;
            }
            
        }

        width: 50%;

    }

    &__title{

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        color: #929292;

    }

    &__subtitle{

        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;

        &_link{

            border-bottom: 1px dashed var(--main);
            color: var(--main);

        }

    }

}
