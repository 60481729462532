.article-properties{

    &__header{

        padding-bottom: 15px;
        border-bottom: 1px solid #EAEAEA;

        &_mod{

            display: flex;

            .article-control{
                margin-top: 0px;
            }

            .article-manager{

                width: 240px;

            }

            .article-counter{

                width: 100px;

            }

        }

    }

    &__wrapper-popup{

        display: flex;
        flex-direction: column;

        & > * {

            &:first-child{

                margin-bottom: 10px;

            }

        }

    }

    &__wrapper-feature{
        padding-right: 15px;
        max-height: calc(100% - 110px);
    }

    &__feature{

        margin-top: 20px;

    }

    &__body{

        border-bottom: 1px solid #eaeaea;

        padding-top: 15px;
        padding-bottom: 15px;

        &-2{

            width: fit-content;
            padding-top: 25px;
            width: inherit;

            @include _small {

                width: 100%;

            }

            .article-filter{

                &:not(:last-child){
                    
                    margin-bottom: 45px;
    
                }    
    
            }

            .article-plus{

                margin-bottom: 20px;

            }

            & > {

                .article-result{

                    margin-bottom: 20px;

                }

            }

        }

    }

    &__footer{

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-top: 15px;

        @include _medium {

            flex-direction: column;
            align-items: flex-start;
        
            
        }

    }

    &__ribbon{

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: calcFluid(15,30);

        @include _small {

            flex-direction: column;
            align-items: flex-start;
            
        }
        
    }

    &__title{

        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;

        color: #333333;

        @include _max {

            font-size: calcFluid(20,36);
            line-height: calcFluid(26,44);
            
        }

    }

    .article-manager{
        margin-left: 25px;
    }

    .simplebar-vertical{

        margin: 20px 0;

    }

}

.article-slider{

    display: flex;
    flex-direction: column;

    width: 100%;
    height: fit-content;
    
    position: relative;
    

    &__image{

        display: block;
        
        object-fit: contain;
        object-position: center;
        
        width: 100%;
        height: 100%;
        
        max-width: 100%;
        max-height: 100%;

        transition: all ease 300ms;

        border-radius: 4px;

    }

    &__view{

        border: 1px solid #EAEAEA;
        border-radius: 4px;

        height: 415px;

        @include _max {

            height: calcFluid(330,415);
            
        }

    }

    &__nav{

        // flex-grow: 1;

        height: 125px;
    

        .slick {

            &-slide{

                position: relative;

                margin-top: auto;

                width: 120px;

                height: 90px;

                padding: 10px;

                filter: grayscale(1);

                &:before{

                    opacity: 0;
                    visibility: hidden;

                    top: -41px;

                    content: '';

                    position: absolute;

                    left: 50%;

                    border-style: solid;
                    border-width: 0 12.5px 20px 12.5px;
                    border-color: transparent transparent var(--main) transparent;

                    transform: rotate(180deg) translateX(50%);

                }

            }

            &-current{

                // margin-top: 0;
                padding: 0;

                // width: 140px !important;

                transform: translateY(-15px);
                
                border: 1px solid var(--main);
                border-radius: 4px;

                transition: all ease 300ms;

                filter: none;

                &:before{
                    
                    transition: all ease 400ms;

                    display: block;
                    top: -21px;

                    opacity: 1;
                    visibility: visible;

                }

            }
    
        }

    }

    &__zoom{

        position: absolute;

        cursor: pointer;

        z-index: 1;

        top: 23px;
        right: 25px;

        width: 23px;
        height: 23px;

        fill: var(--main);

    }

    &__bagdes{

        left: 15px;
        top: 15px;
        z-index: 1;

    }

    &__item{

        &_stand{

            // pointer-events: none;

            align-items: center;
            justify-content: center;

            svg{

                transform: scale(1.67);

            }

        }

        &_cup{

            background-color: #F1F7FA;

        }

    }

    &__cup{  

        

    }

    &-arrow{

        position: absolute;

        width: 30px;
        height: 42px;

        border: none;
        border-radius: 4px;

        cursor: pointer;

        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

        z-index: 1;
        bottom: 25px;

        transition: all ease 400ms;

        path{
            transition: all ease 400ms;
        }

        &:hover{

            background-color: var(--main);

            path{
                fill: #FFFFFF;
            }

        }

        &_next{

            right: 0;

        }

        &_prev{

            left: 0;

        }

    }

    .slick {

        &-list{

            padding: 0 !important;
            overflow: hidden;

        }

        &-track{

            display: flex;
            height: 100%;

            // transform: none !important;
            
        }

        &-slide{

            display: flex;

            max-height: 415px;

        }

    }

}

.article-manager{

    display: grid;

    max-width: 100%;
    width: 350px;

    grid-template-areas:
        "menu cart"
        "buy buy";

    grid-template-columns: 100px 1fr;
    grid-template-rows: repeat(2, 42px);

    grid-column-gap: 16px;
    grid-row-gap: 10px;

    &__counter{
        grid-area: menu;
    }

    &__cart{
        grid-area: cart;
    }

    &__buy{
        grid-area: buy;
    }

    .btn{

        display: flex;
        align-items: center;
        justify-content: center;

    }

}

.acticle-observe{

    $this:&;

    display: flex;
    align-items: center;

    cursor: pointer;

    &__svg{

        width: 16px;
        height: 16px;

        margin-right: 10px;

    }

    &__title{

        font-style: normal;
        font-weight: bold;

        font-size: 13px;
        line-height: 22px;

        transition: all ease 400ms;

    }

    &:hover{

		#{$this}__title{
			color: var(--main);
		}

		svg{
			fill: var(--main);
		}

	}
	&:active{

        #{$this}__title{
			color: var(--main-darker);
		}

		svg{

			fill: var(--main-darker);

		}

	}

	&_disable{

		pointer-events: none;
		opacity: 0.8;

		svg{
			fill: #B6B6B6;
		}

		#{$this}__title{

			color: #B6B6B6;

		}

	}
    &__code{
        font-style: normal;
        font-weight: 500;
        font-size: 13px;  
        line-height: 22px;
        color: #bbb;
    }

}

.article-warehouse{

    display: flex;
    align-items: center;

    cursor: pointer;

    &__svg{

        width: 16px;
        height: 16px;

        fill: var(--main);

        margin-right: 6px;

    }

    &__title{

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;

    }

}

.article-counter{

    display: flex;

    position: relative;

    width: 100px;
    height: 42px;

    border: 1px solid #D5D5D5;
    border-radius: 4px;

    background: #FFFFFF;
    

    &__value{
        
        border: none;

        width: 100%;

        text-align: center;

    }

    &__compute{

        cursor: pointer;

        &_minus{
            padding-left: 13px;
        }

        &_plus{
            padding-right: 13px;
        }

        &_disable{ 

            cursor: auto;
            fill: #d5d5d5;

        }

    }

    &__svg{

        flex-shrink: 0;
        
        width: 13px;
        height: 100%;

    }

}
.article{

    &__stand{

        display: grid;

        grid-template-columns: 50% 1fr;
        column-gap: 32px;

        @include _Extra_large {

            grid-template-columns: 100%;

            row-gap: 30px;
            
        }
        
        &-column{

            &:first-child{

                max-width: 640px;
                
                @include _Extra_large {
                    
                    margin: 0 auto;

                }
               
            }

        }

    }

    &__content{

        margin-top: 50px;

        @include _max {

            margin-top: calcFluid(25,50);
            
        }

    }

    &__more{

        margin-top: 60px;

        display: grid;

        grid-template-areas: "description receive";
        grid-template-columns: 840px 1fr;

        column-gap: 60px;

        @include _max {
            
            margin-top: calcFluid(25,60);
            
        }

        @include _Extra_large {

            grid-template-areas: 
            
                "receive"
                "description"
            
            ;
            grid-template-columns: 100%;

            row-gap: 30px;
            
        }

        &-description{

            grid-area: description;

        }

        &-receive{

            grid-area: receive;

            max-width: 420px;
            width: 100%;

        }

    }

    &__like{

        margin-top: 70px;

        @include _max {
            
            margin-top: calcFluid(30,70);
            
        }

    }

}

.article-control{

    display: flex;
    align-items: center;

    margin-top: 30px;

    @include _small {
        
        flex-wrap: wrap;

        margin-top: 15px;
        
    }

    &__item{

        &:first-child{

            
            @include _small_ {
                
                margin-right: 45px;

            }

            @include _small {
             
                width: 100%;

                margin-bottom: 10px;

            }

        }

        &:not(:first-child):not(:last-child){

            margin-right: 30px;

        }

    }

}

.article-feature{

    &__tabs{

        display: flex;

        margin-bottom: 25px;

        border-bottom: 1px solid #EAEAEA;

        @include _medium {

            flex-direction: column;
            
        }

    }

    &-content{

        &__ribbon{

            display: flex;
            flex-direction: column;

            &:not(:last-child){
                margin-bottom: 25px;
            }

            &-title{

                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;

                margin-bottom: 12px;

            }

            .text-line{

                margin-bottom: 12px;

            }

            & > * {

                &:last-child{

                    margin-bottom: 0px;

                }

            }

        }

    }

}

.article-price{

    $this:&;

    &_current{

        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 44px;

    }

    &_before{

    }
    
    &_old{

        font-style: normal;
        font-weight: normal;

        font-size: 18px;
        line-height: 20px;

        text-decoration-line: line-through;

        color: #B6B6B6;

    }

    &_from{

        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 44px;

        color: var(--main);

        @include _small {

            margin-bottom: 5px;

        }

    }

    &_min{

        display: flex;

        & > * {

            &:first-child{
                margin-right: 12px;
            }

        }

        #{$this}{

            &__svg{

                width: 21px;
                height: 21px;

                fill: var(--main);

            }

            &__title{

                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 20px;

                color: #929292;

                b{

                    font-weight: bold;
                    color: var(--main);

                }

                @include _max { 

                    font-size: calcFluid(13,15);
                    
                }

            }
    
        }

    }

} 