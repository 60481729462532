
// Многорозовые классы

.show-all{

    margin-top: 15px;
    color: var(--main);
    font-size: 15px;
    line-height: 1;

    cursor: pointer;

    &_hidden{
        display: none;
    }

    svg{
        width: 10px;
        height: 6px;
        margin-left: 13px;
        fill: var(--main);
        transition: transform $tr-time;
    }

    &::before{
        content: "Показать еще";
    }

    &.active{
        &::before{
            content: 'Скрыть';
        }
        svg{
            transform: rotate(180deg);
        }
    }

}

.show-more{

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--main);
    border-radius: 4px;

    background: transparent;

    width: 100%;
    height: 42px;

    &::before{

        content: 'Показать еще';
        
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        
        color: #fff;

    }

}

.select-all{

    display: flex;
    align-items: center;

    .filter-item{

        &__select{

            margin-right: 11px;
            
        }

    }

}

.pagination{

    $this:&;

    display: flex;

    width: fit-content;
    height: 42px;
    margin: 0 auto;
    &-item{

        display: flex;
        justify-content: center;
        align-items: center;

        list-style: none;

        width: 44px;

        cursor: pointer;

        transition: all ease 400ms;

        &:hover{

            background-color: #EBF7F6;
            border-radius: 5px;

            #{$this}{

                &-link{

                    color: var(--main)

                }
    
            }

        }

        &_arrow{

            width: 30px;

            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 4px;

            &-left{
                margin-right: 20px;
            }

            &-right{
                margin-left: 20px;
            }

        }

        &_current{

            position: relative;
            pointer-events: none;

            #{$this}{

                &-link{

                    color: var(--main)

                }
    
            }

            &:after{

                content: '';

                position: absolute;

                width: 40px;
                height: 2px;

                background-color: var(--main);

                top: 100%;

            }

        }

    }

    &-link{

        pointer-events: none;

        font-style: normal;
        font-weight: bold;
        font-size: 16px;

        line-height: 21px;

        text-align: center;

        color: #B6B6B6;

        &--url{
            padding: 10px !important;
            pointer-events: all !important;
        }
    }

}

.block-list{

    padding: 20px;
    background-color: #EBF7F6;

    &__item{

        display: block;

        height: auto;

        transition: all ease 400ms;

        font-style: normal;
        font-weight: normal;

        font-size: 16px;
        line-height: 20px;

        width: 84%;
        
        &:not(:last-child){
            margin-bottom: 18px;
        }

        &_hidden{

            display: none;

        }

        @include _small {

            width: 100%;

            font-size: 15px;

            &:not(:last-child){
                margin-bottom: 14px;
            }

        }

    }

    &__wrapper{
        position: relative;
    }

    &__show-all{

        &::before{

            content: 'Показать все';

        }

        &_active{

            &::before{

                content: 'Свернуть';
    
            }

            svg{

                transform: rotate(180deg);

            }

        }

    }

}

.block-receive{

    display: block;

    padding: 25px 20px;

    border: 1px solid #EAEAEA;
    border-radius: 3px;

    height: fit-content;

    background: #FBFBFB;

    &__item{

        &:not(:last-child){

            padding-bottom: 25px;
            margin-bottom: 15px;

            border-bottom: 1px solid #EAEAEA;

        }
        
    }

    &-item{

        padding: 0 12px;

        &__header{

            display: flex;
            align-items: center;

            margin-bottom: 10px;
    
        }

        &__title{

            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;

            color: var(--main);

        }

        &__svg{

            width: 36px;
            height: 36px;

            margin-right: 12px;

            fill: var(--main);

        }

        &__content{

            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;

            color: #929292;

        }

    }

}


.block-subscribe{

    $this:&;

    background-color: #333333;
    color: #fff;

    padding: 35px 0;

    &__title{

        font-size: calcFluid(18,20);      
        margin-bottom: 8px;

    }

    &__text{

        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;

    }

    &_mod-1{

        #{$this}{

            &__tilte{

                margin-bottom: 8px;

            }

            &__wrapper{

                display: flex;
                align-items: center;
                justify-content: space-between;
        
                @include _Extra_large {
                    
                    flex-direction: column;
                    align-items: flex-start;
        
                    & > * {
        
                        width: 100%;
        
                        &:not(.block-subscribe__wrapper-form){
        
                            margin-bottom: 25px;
        
                        }
        
                        @include _small_ {
                            
                            max-width: 420px;
        
                        }
        
                    }
                    
                }
        
            }
        
            &__wrapper-text{
        
                @include _Extra_large_ {
        
                    width: 416px;
                    
                }
        
            }
        
            &__wrapper-form{
        
                flex-grow: 1;
                
                @include _Extra_large_ {
                    max-width: 528px;
                    padding: 0 15px;
                }
        
            }
        
            &__wrapper-info{
        
                width: 270px;
        
                
                @include _Extra_large_ {
        
                    margin-top: 10px;
                    margin-bottom: auto;
                    
                }
        
                @include _Extra_large {
                    
                    order: -1;
                    
                }
        
            }
        
        }

    }

    &_mod-2{

        background-color: #2C2C2C;

        #{$this}{

            &__wrapper{

                display: flex;
                align-items: center;
                justify-content: space-between;

                @include _Extra_large {
                    
                    flex-direction: column;
                    align-items: flex-start;

                    & > * {

                        width: 100%;
                        max-width: 420px;

                        &:not(:last-child){

                            margin-bottom: 25px;

                        }

                    }                    
                    
                }

            }

            &__wrapper-text{
        
                @include _Extra_large_ {
        
                    width: 254px;
                    
                }
        
            }

            &__wrapper-info{

                @include _Extra_large_ {
        
                    width: 416px;
                    
                }

            }

            &__wrapper-form{

                flex-grow: 1;
                
                @include _Extra_large_ {

                    max-width: 530px;
                    
                }

            }
    
        }

    }

    .personal{

        margin-top: 8px;
        color: #929292;

    }

}

.block-subscribe-2{

    position: relative;

    &__title{

        font-size: calcFluid(18,20);
        margin-bottom: 8px;

    }

    &__text{

        display: block;

        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;

    }

    &__wrapper{

        display: flex;

        @include _Extra_large {
            
            & > * {

                width: 100%;
                
                @include _small_ {

                    max-width: 420px;
                    
                }
    
            }

        }

    }

    &__wrapper-content{

        @include _Extra_large_ {

            width: 530px;
            margin-right: 70px;
            
        }
    }

    &__separate{
        margin-bottom: 35px;
    }

    &__image{

        display: block;

        object-fit: cover;
        object-position: center;

        width: 100%;
        height: 100%;

        max-width: 100%;
        max-height: 100%;

        &-wrap{

            position: absolute;

            display: flex;

            width: 53vw;
            height: 230px;

            right: 0;

            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            overflow: hidden;

            @include _Extra_large {
                
                display: none;

            }

        }

    }

    &__separate{

        width: 270px;

    }

    .form-wrap{

        &__content{

            max-width: 100%;
            margin-bottom: 25px;
            
            @include _Extra_large_ {
                width: 390px;
            }

        }

    }

    .personal{

        color: #929292;

    }

}

.block-info-separate{

    display: flex;
    justify-content: space-between;

    &__text{

        font-style: normal;
        font-weight: normal;

        color: #929292;

        font-size: 15px;
        line-height: 20px;

    }

    &__line{

        width: 1px;
        background-color: #929292;

    }

    & > * {

        &:first-child{

            padding-right: 15px;

        }

        &:last-child{

            padding-left: 15px;

        }

    }

    .contact-item{
        width: 50%;
        word-break: break-word;
    }

}

.search{

    display: flex;

    height: 47px;

    position: relative;

    border: 1px solid #eaeaea;
    border-radius: 4px;

    overflow: hidden;

    &__input{

        display: block;
        width: 100%;

        border: 0;

        padding: 0 12px;

        &::-webkit-input-placeholder {

            font-size: 15px;
            line-height: 20px;

			color: #B6B6B6;
            
		}

    }
    
    &__label{

        position: absolute;

        font-size: 15px;
        line-height: 20px;

        color: #B6B6B6;

        left: 12px;

        top: 50%;
        transform: translateY(-50%);
        
        pointer-events: none;

        transition: $tr-time;


    }

    &__svg{

        position: absolute;

        fill: #B6B6B6;

        width: 19px;
        height: 100%;

        right: 14px;
        top: 50%;
        transform: translateY(-50%);

    }

}

.toggle-view{

    display: flex;

    &-item{

        $this:&;

        // cursor: pointer;

        &:hover{

            svg{

                fill: var(--main);

            }

		}

        // &:first-child{

        //     // margin-right: 12px;

        // }

        &_active{

            #{$this}{

                &__svg{

                    fill: #333333;

                }

            }

        }

        &__svg{

            fill: #B6B6B6;
            cursor: pointer;

            width: 24px;
            height: 24px;

            &-column{

            }

            &-row{

            }

            @include _large_ {

                width: calcFluid(20,24,0,991,1920);
                height: calcFluid(20,24,0,991,1920);
                
            }

            @include _large {

                display: none;
               
            }

        }

    }

}

.svg{

    &-pagination{

        width: 9px;
        height: 16px;

        fill: #B6B6B6;
        border-radius: 0.8px;

    }

    &-favorite{

        &:hover{

            fill: var(--main) !important;
    
        }

        use{

            display: block;

            &:last-child{

                display: none;

            }

        }

        &_active{

            use{

                &:first-child{

                    display: none;

                }

                &:last-child{

                    display: block;

                    fill: var(--main);
    
                }

            }

        }

    }

    &-wish{

        fill: #B6B6B6;

        &__use-full{

            fill: var(--main);

        }

    }

    &-compare{

        fill: #B6B6B6;

    }

    &-close{

        &:hover{

            fill: var(--main) !important;
    
        }

    }

}

.social{

    display: flex;
    flex-wrap: wrap;

    margin: -6px 0;

    &-item{

        display: flex;

        margin: 6px;

        width: 31px;
        height: 31px;

        &__svg{

            width: 100%;
            height: 100%;

        }

    }

}

.come-back{

    $this:&;
    

    display: flex;
    align-items: center;

    width: fit-content;

    padding: 10px 24px;

    border: 1px solid var(--main);
    border-radius: 4px;

    transition: all ease 400ms;

    &:hover{

        #{$this}{

            &__title{

                color: var(--main-darker);

            }
    
        }

    }

    &:active{

        background-color: var(--main);

        #{$this}{

            &__title{

                color: #fff;

            }

            &__svg{

                fill: #fff;

            }

        }

    }

    &:focus{

        background-color: var(--main-lighter);

    }

    &_disable{

        pointer-events: none;
        border-color: #B6B6B6;

        #{$this}{
        
            &__title{
        
                color: #B6B6B6;
        
            }
        
        }

    }

    &__svg{

        width: 13px;
        height: 13px;

        margin-right: 13px;

        fill: #B6B6B6;

    }

    &__title{

        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;

        color: #333333;

    }

}

.text-line{

    display: flex;

    &__item{

        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;

    }

    &__ribbon{

        flex-grow: 1;
        border-bottom: 1px dashed #B6B6B6;

        margin: 0 10px;
        margin-bottom: 5px;

    }

}

.btn-remove{

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    text-decoration-line: underline;

    color: #ED6262;

}

.btn-filter{

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    border: 1px solid #EAEAEA;
    border-radius: 4px;

    background-color: rgba(234, 234, 234, 0.5);

    &__svg{

        cursor: pointer;

        width: 24px;
        height: 24px;

        margin-right: 10px;

        fill: #929292;

    }

    &__title{

        font-style: normal;
        font-weight: bold;

        color: #929292;

        font-size: 15px;
        line-height: 22px;

    }

}

.btn-menu{

    display: flex;
    align-items: center;
    justify-content: center;

    &_mod-1{

        width: 100%;
        height: 54px;

    }

    &__title{

        display: block;

        font-style: normal;
        font-weight: bold;

        font-size: 15px;
        line-height: 22px;

        margin-right: 10px;

        color: #fff;

    }

    &__svg{

        width: 15px;
        height: 13px;
        
        transform: rotate(-90deg);

        fill: #fff;

    }

}

.block-alert{

    display: flex;
    padding: 25px 30px;

    border: 1px dashed var(--main);
    border-radius: 4px;

    &_mod{

        width: fit-content;

        flex-direction: column;
        padding: 20px 30px;

        background-color: #F8F8F8;

        border: 1px dashed #B6B6B6;

    }

    &__svg{

        fill: var(--main);

        width: 42px;
        height: 42px;

    }

    &__text{

        display: block;

        font-style: normal;
        font-weight: normal;
        
        font-size: 15px;
        line-height: 20px;

        b{

            color: var(--main);

        }

        &_small{

            font-style: normal;
            font-weight: normal;

            font-size: 12px;
            line-height: 18px;

        }

        &_bold{

            font-weight: bold;

        }


    }

}

.item-warning{

    $this:&;
    
    position: absolute;

    width: 22px;
    height: 22px;
    background-color:#FFD98E;

    z-index: 1;

    border-radius: 50%;

    &:hover{

        #{$this}{

            &__inner{

                opacity: 1;
                visibility: visible;

            }
    
        }
        
    }

    

    &__wrapper{

        position: relative;

        display: flex;

        width: 100%;
        height: 100%;

        &::before{

            content: '!';
    
            margin: auto;
    
        }

    }

    &__inner{

        display: block;

        position: absolute;

        top: calc(100% + 8px);
        transform: translateX(-50%);
        left: 11px;

        width: 180px;

        border: 1px solid #EAEAEA;
        border-radius: 5px;

        background-color: #fff;
        z-index: 1;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;

        transition: all ease 400ms;

        opacity: 0;
        visibility: hidden;

        color: #333333;

        padding: 15px 18px;

        &::before{

            content: '';

            position: absolute;

            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);

            border-style: solid;
            border-width: 0 5px 8px 5px;

            border-color: transparent transparent var(--main) transparent;

        }

    }

}

.block-contact{

    &-item{
        
        display: flex;

        &:not(:last-child){

            margin-bottom: 25px;

        }

        &__wrapper{
            display: flex;
            flex-direction: column;
        }

        &__title{

            font-style: normal;
            font-weight: normal;

            font-size: 14px;
            line-height: 22px;

            &_bold{

                font-weight: bold;

            }

        }

        &__svg{

            fill: var(--main);

            margin-top: 2px;
            margin-right: 15px;

            flex-shrink: 0;

            width: 20px;
            height: 20px;

        }

    }

}

.block-address{

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

}


.region-select{

    &__title{

        display: block;

        font-style: normal;
        font-weight: normal;

        font-size: 15px;
        line-height: 20px;

        margin-bottom: 10px;

    }

    &__header{

        margin-bottom: 35px;

    }

    &__item{

        &:not(:last-child){

            margin-bottom: 32px;

        }

    }

}

.region-place{

    border: 1px solid #EAEAEA;
    border-radius: 4px;

    padding: 20px 20px 20px 30px;

    &__title{

        font-style: normal;
        font-weight: bold;

        font-size: 16px;
        line-height: 24px;

        @include _small {
            
            order: 1;
            
        }

    }

    &__svg{

        fill: var(--main);

        width: 20px;
        height: 20px;

    }

    &__info{

        display: flex;
        align-items: center;
        flex-shrink: 0;

        &:first-child{

            @include _small_ {

                margin-right: 10px;
                
            }

            @include _small {
                    
                margin-bottom: 5px;
                
            }

        }

        & > * {

            &:first-child{

                margin-right: 7px;                    

            }

        }
    
        &-title{

            font-style: normal;
            font-weight: normal;

            font-size: 14px;
            line-height: 22px;

        }

    }

    &__top{

        display: flex;
        justify-content: space-between;

        margin-bottom: 15px;

        @include _small {
            
            flex-direction: column;
            
        }

    }

    &__bottom{

        display: flex;
        justify-content: space-between;

        
        @include _small_ {

            margin-right: 30%;
            
        }

        @include _small {
            
            flex-direction: column;
            
        }

    }

    .show-map{

        flex-shrink: 0;

        @include _small {
            
            margin-bottom: 10px;

        }

    }

}

.show-map{

    display: flex;

    &__svg{

    }

    &__title{

        font-style: normal;
        font-weight: bold;

        font-size: 13px;
        line-height: 22px;

        text-decoration-line: underline;

        color: var(--main);

    }

}

.select-drop{

    $this:&;
    
    position: relative;

    &_active{

        #{$this}{

            // &__dropout{

            //     opacity: 1;
            //     visibility: visible;

            // }

            &__svg{

                transform: translateY(-50%) rotate(180deg);

            }
    
        }

    }

    &__svg{

        position: absolute;

        pointer-events: none;

        width: 12px;
        height: 8px;

        right: 20px;
        top: 50%;

        transform: translateY(-50%);

        fill: #929292;

        transition: $tr-time;

    }

    &__value{

        color: #B6B6B6;

    }

    &__dropout{

        position: absolute;

        width: 100%;

        top: calc(100% + 1px);

        padding: 15px;
        
        // opacity: 0;
        // visibility: hidden;
        // transition: all ease 350ms;

        z-index: 5;

        // background-color: red;

    }

    &__option{

        display: block;

        font-style: normal;
        font-weight: normal;

        font-size: 16px;
        line-height: 24px;

        margin-bottom: 10px;

        display: block;

    }

    &__select{

        cursor: pointer;

    }

    &__input{

        pointer-events: none;

    }

}

.list-menu{

    display: flex;
    flex-direction: column;

    transition: all ease 400ms;

    @include _Extra_large {
            
        position: fixed;
        
        width: 100%;
        height: calc(100vh - 60px);

        top: 60px;
        left: 0;

        z-index: 5;

        background-color: #fff;

        transform: translateX(100%);

        overflow: overlay;

    } 

    &__wrapper{

        display: flex;
        flex-direction: column;

        @include _Extra_large {

            height: fit-content;
            
        }

        & > * {

            &:nth-child(2){
    
                @include _Extra_large_ {
    
                    border-top: 1px solid #EAEAEA;
    
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    
                }
                
                &.active{
    
                    border-top: 1px solid var(--main);
    
                }
        
            }
        
            &:last-child{
        
                
                @include _Extra_large_ {
                    
                    border-bottom: 1px solid #EAEAEA;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    
                }
    
                &.active{
    
                    border-bottom: 1px solid var(--main);
    
                }
        
            }
    
        }

    }

    &.active{

        transform: none;

    }

    &-item{

        $this:&;

        display: flex;
        align-items: center;
        justify-content: space-between;

        transition: all ease $tr-time;

        &:hover{

            background-color: #EBF7F6;

        }

        &.active{

            #{$this}{

                &__title{

                    @include _Extra_large_ {

                        color: #fff;
                        font-weight: bold;
                        
                    }

                }

                &__svg{

                    fill: #fff;

                }

            }

            @include _Extra_large_ {

                background-color: var(--main);

                border-left: 1px solid var(--main);
                border-right: 1px solid var(--main);
                
            }
        
            @include _Extra_large {

                background-color: #D6EFEE;
                
            }

        }

        &__title{

            color: #333333;

            font-style: normal;
            font-weight: normal;

            font-size: 15px;
            line-height: 20px;

        }

        &__svg{

            width: 12px;
            height: 8px;

            transform: rotate(-90deg);

            transition: $tr-time;

            @include _Extra_large {
                
                display: none;
                
            }

        }
        
        @include _Extra_large_ {

            padding: 17px;

            border-left: 1px solid #EAEAEA;
            border-right: 1px solid #EAEAEA;
            
        }

        @include _Extra_large {

            height: 56px;
            padding: 0 30px;

            &:not(:last-child){

                border-bottom: 1px solid #eaeaea;

            }

            &:first-child{

                border-top: none;
                border-radius: 0;

            }

            &:last-child{

                background-color: #EAEAEA;

                #{$this}{

                    &__title{

                        color: #929292;

                    }
                
                }

            }
            
        }

    }

    &-back{

        display: flex;
        align-items: center;

        background-color: var(--main);

        height: 44px;

        padding: 0 20px;

        cursor: pointer;

        @include _Extra_large_ {

            display: none;

        }

        &__svg{

            width: 15px;
            height: 12px;

            margin-right: 10px;

            transform: rotate(90deg);

            fill: #fff;

        }

        &__title{

            font-style: normal;
            font-weight: normal;

            font-size: 15px;
            line-height: 20px;
            
            color: #fff;

        }

    }

}

.list-order{

    @include _medium {

        overflow: overlay;

    }

    &__wrapper{

        display: flex;
        flex-direction: column;

        min-width: 735px;

        & > * {

            &:last-child{

                border-bottom: 1px solid #EAEAEA;
                
            }

        }

    }

    &-item{

        $this:&;

        border: 1px solid #EAEAEA;
        border-bottom: none;

        transition: all ease 350ms;

        &.error,
        &.success{

            #{$this}{

                &__svg{
        
                    &-status{
        
                        width: 20px;
                        height: 14px;
        
                    }
        
                }
            
            }

        }


        &.active{

            border-color: var(--main);
            border-bottom: 1px solid var(--main);

            & + * {

                border-top: none;

            }

            #{$this}{

                &__header{

                    border-bottom: 1px solid #EAEAEA;

                }

                &__svg{

                    &-arrow{

                        transform: rotate(-180deg);

                    }

                }
            
            }

        }


        &.processing{

            #{$this}{

                &__svg{
        
                    &-status{
        
                        fill: #FFD98E;
        
                    }
        
                }

                &__status{

                    color: #FFD98E;

                }

            }

        }

        &.error{

            #{$this}{

                &__svg{
        
                    &-status{
        
                        fill: #ED6262;
        
                    }
        
                }

                &__status{

                    color: #ED6262;

                }

            }

        }
            
        &.success{

            #{$this}{

                &__svg{
        
                    &-status{
        
                        fill:#32B0A9;
        
                    }
        
                }

                &__status{

                    color:#32B0A9;

                }

            }

        }

        &__wrapper-text{

            display: flex;
            flex-grow: 1;
            justify-content: space-between;

            margin-left: auto;
            margin-right: 22px;

        }        

        &__padding{

            padding: 0 23px;

        }

        &__header{

            display: flex;
            align-items: center;
            justify-content: space-between;

            cursor: pointer;

            padding-right: 0px;

            height: 57px;

        }

        &__drop{

            padding-top: 15px;
            padding-bottom: 20px;      

        }


        &__price{

            font-style: normal;
            font-weight: bold;

            font-size: 16px;
            line-height: 26px;

        }

        &__title{

            display: block;

            font-style: normal;
            font-weight: bold;

            font-size: 18px;
            line-height: 26px;

            margin-right: 10px;

            &-wrap{

                display: flex;
                align-items: center;

            }

        }

        &__svg{

            &-arrow{
                
                transition: all ease 350ms;

                width: 15px;
                height: 10px;

            }

            &-status{

                margin-right: 10px;

                width: 20px;
                height: 20px;

            }

        }

        &__status{

            font-style: normal;
            font-weight: 500;

            font-size: 15px;
            line-height: 22px;

            &-wrap{

                display: flex;
                align-items: center;

                height: 100%;

                border-left: 1px solid #EAEAEA;

                padding-left: 23px;

                width: 162px;
                
            }

        }

        &__submit{

            display: flex;
            align-items: center;
            justify-content: center;

            margin-top: 20px;

            margin-left: auto;

            width: 170px;
            height: 44px;

        }

        .text-line{

            &:not(:nth-last-child(2)){

                margin-bottom: 15px;

            }

            &__after{

                font-size: 16px;
                line-height: 24px;

            }

        }


    }

}

.date-picker{

    position: relative;
    // max-width: 192px;
    
    &__svg{

        position: absolute;

        transition: all ease 300ms;

        top: 50%;
        transform: translateY(-50%);

        &_calendar{

            fill: var(--main);

            left: 18px;

            width: 24px;
            height: 24px;

        }

        &_arrow{

            right: 12px;

            fill: #929292;

            width: 13px;
            height: 12px;

        }

    }

    &__input{

        position: relative;

        padding: 10px 15px 10px 49px;

        max-width: 100%;
        background: transparent;

        border: 1px solid #EAEAEA;
        border-radius: 5px;

        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        
        color: #929292;

        cursor: default;

        &:focus{

            & + svg{

                transform: translateY(-50%) rotate(180deg);

            }
    
        }

    }

}

.control-ribbon{

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 18px;

    border-bottom: 1px solid #EAEAEA;

    &__sort{

        @include _large {

            flex-direction: column;
            height: fit-content !important;
    
            .block-tab-sort{
    
                &__grid{
    
                    margin-top: 15px;
                    flex-direction: column;
    
                    .tab-item-sort{
                        
                        padding: 6px 14px;
    
                        &:not(:last-child){
    
                            margin-bottom: 15px;
    
                        }
    
                    }
    
                }
    
            }
            
            .toggle-view{
                display: none;
            }
            
        }

    }

}

.control-count{

    $this:&;

    display: flex;

    &_bonus{

        align-items: center;

        #{$this}{

            &-number{

                margin-left: 10px;

            }

            &__subtitle{

                margin-left: 25px;

            }
    
        }

    }

    &__title{

        font-style: normal;
        font-weight: bold;

        font-size: 15px;
        line-height: 20px;

    }

    &-number{

        display: flex;
        align-items: center;

        position: relative;

        font-style: normal;
        font-weight: bold;

        font-size: 16px;
        line-height: 20px;

        color: var(--main);

        border: 1px solid #EAEAEA;
        border-radius: 4px;

        height: 30px;

        padding: 0 10px;

    }

    &__subtitle{

        font-style: normal;
        font-weight: normal;
        
        font-size: 13px;
        line-height: 20px;

        color: #929292;

    }

}

.award{

    display: flex;
    position: relative;

    max-width: 100%;

    width: 560px;
    justify-content: space-between;

    @include _small {
        
        flex-direction: column;
        align-items: center;
        
    }

    &-line{

        position: absolute;

        top: 37px;
        left: 50%;

        width: 60%;
        height: 3px;

        background-color: rgba(50,176,169,.2);
        transform: translateX(-50%);

        @include _small {
            
            display: none;
            
        }

    }
    
    &-item{

        display: flex;
        flex-direction: column;
        align-items: center;

        width: 170px;

        @include _small {
            
            &:not(:last-child){

                margin-bottom: 25px;

            }
            
        }

        &__img{

            display: block;
            
            object-fit: cover;
            object-position: center;
            
            width: 100%;
            height: 100%;
            
            max-width: 100%;
            max-height: 100%;

            &-wrap{

                display: flex;

                width: 80px;
                height: 80px;

                z-index: 1;
                background-color: #fff;

                margin-bottom: 30px;

            }
    
        }
    
        &__title{

            text-align: center;
    
            font-style: normal;
            font-weight: normal;
    
            font-size: 14px;
            line-height: 20px;
    
        }

    }
    

}

.notify-block{

    $this:&;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 35px 40px;

    border: 1px solid #EAEAEA;
    border-radius: 4px;

    @include _large {
        
        flex-direction: column;

        & > * {

            &:not(:last-child){

                margin-bottom: 20px;

            }

        }
        
    }

    @include _small {
        
        padding: 20px;

    }

    &_order{



    }

    &_mail{

        #{$this}{

            &__svg{

                fill: var(--main);

            }

    
        }

    }

    &_order{

        #{$this}{

            &__svg{

                fill: var(--main);

            }
    
        }

    }

    &__svg{
        
        fill: #DBDBDB;
        
        width: 48px;
        height: 48px;
        
        @include _large_ {
            
            align-self: flex-start;

        }

    }

    &__text{

        display: flex;
        flex-direction: column;

        max-width: 485px;

        @include _large {
            
            text-align: center;
            
        }

        @include _large_ {
            
            margin-left: 40px;
            margin-right: auto;

        }

    }

    &__title{

        display: block;

        margin-bottom: 10px;

        font-style: normal;
        font-weight: bold;

        font-size: 22px;
        line-height: 26px;

    }

    &__subtitle{

        font-style: normal;
        font-weight: normal;

        font-size: 16px;
        line-height: 24px;

        color: #B6B6B6;

    }

    &__btn{

        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 192px;
        height: 42px;

        font-style: normal;
        font-weight: bold;

        font-size: 15px;
        line-height: 22px;

        @include _small {
            
            width: 100%;
            
        }

        &_mod{

            min-width: 228px;
            
        }

    }

    &--hide{
        display: none;
    }

}

.block-404{

    display: flex;
    flex-direction: column;
    align-items: center;

    &__stand{

        display: block;
        
        object-fit: contain;
        object-position: center;
        
        width: 100%;
        height: 100%;
        
        max-width: 100%;
        max-height: 100%;

        &-wrap{

            display: flex;

            max-height: 300px;
            max-width: 640px;

            margin-bottom: 35px;

        }

    }

    &__title{

        display: block;

        text-align: center;

        font-style: normal;
        font-weight: bold;
        font-size: 42px;
        line-height: 54px;

        color: var(--main);

        margin-bottom: 20px;

        @include _max {
            
            font-size: calcFluid(28,42);
            line-height: calcFluid(34,54);
            
        }

    }

    &__subtitle{

        display: block;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

        text-align: center;

        margin-bottom: 40px;

    }

    &__btn{

        display: flex;
        align-items: center;
        justify-content: center;

        width: 238px;
        height: 46px;

    }

}

.tab-type{

    $this:&;

    display: flex;

    width: 100%;

    background-color: #F4F4F4;
    border-radius: 2px;

    &_compare{

        @include _small_ {

            height: 46px;
        
        }
    
        @include _small {

            padding: 20px;
            
            flex-direction: column;
            
        }

        #{$this}{

            
    
        }

    }

    &-item{

        display: flex;
        align-items: center;

        position: relative;

        
        @include _small {
            
            width: fit-content;

            &:not(:last-child){

                margin-bottom: 20px;

            }
            
        }
        
        @include _small_ {
            
            padding: 0 20px;

            &:not(:last-child){

                margin-right: 15px;
    
            }
            
        }

        &__title{

            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 24px;

            letter-spacing: -0.02em;

            color: #A2A2A2;

        }

        &::after{

            content: '';

            position: absolute;

            background-color: var(--main);

            left: 0;
            bottom: 0;

            width: 100%;
            height: 4px;

            border-radius: 2px;

            opacity: 0;
            visibility: hidden;

        }


        &.active{

            &::after{

                opacity: 1;
                visibility: visible;

            }
            
        }

    }

}

.popup-product{

    height: 640px;

    &__body{

        height: calc(100% - 65px);

        display: flex;

        padding-top: 15px;
        padding-bottom: 50px;
        padding-left: 25px;
        padding-right: 45px;
        
        & > * {

            &:first-child{

                width: 390px;

                margin-right: 30px;

            }

        }

    }

    &__slider{

        height: 320px;

    }

    &__close{

        position: relative;

    }

    &__header{

        padding: 0 25px;

        padding-top: 25px;
        padding-bottom: 20px;

        border-bottom: 1px solid #EAEAEA;;

    }

    .article-slider{

        height: fit-content;

        &__view{

            height: 245px;

        }

        &__nav{

            height: 95px;
           
        }

        &-arrow{

            bottom: 0;

            &_prev{

                left: -15px;

            }

            &_next{

                right: -15px;

            }

        }

        &__item_nav{

            width: 80px !important;
            height: 70px !important;

        }

        .slick-center{

            padding: 5px;
            transform: translateY(-5px);

        }

    }

}

.dropdown-search{

    &__title{

        font-weight: 500;
        font-size: 15px;
        line-height: 20px;

    }

    &__result{

        padding-right: 30px;
        max-height: 250px;

        &-art{

            font-size: 15px;
            line-height: 20px;

            margin-bottom: 8px;

        }

        &-cat{

            font-weight: bold;

            font-size: 15px;
            line-height: 20px;

            margin-bottom: 12px;

        }

        .simplebar-content{

            display: flex;
            flex-direction: column;

            .highlight{
                color: var(--main);
            }

        }

    }

}

.news-grid{

    

}


.state-component{

    &--1{

        &.visible{

            fill: var(--main);
    
        }

    }

    &--2{

        visibility: hidden;

        &.visible{

            visibility: visible;
    
        }    

    }

    
}

.hide-component{

    $this:&;

    &.active{

        #{$this}__state--1{

            opacity: 0;
            visibility: hidden;

        }

        #{$this}__state--2{

            opacity: 1;
            visibility: visible;

        }

    }

    &__state{

        transition: all ease 400ms;

        &--1{



        }

        &--2{

            opacity: 0;
            visibility: hidden;
    
        }

    }

}

.slider-narrow{

    position: relative;

    height: 300px;
    
    @include _Extra_large {

        height: calcFluid(70,300);
        
    }

    &__slide{
        display: flex;
        height: auto;
    }

    &__arrow{

        position: absolute;

        top: 0;
        bottom: 0;

        z-index: 1;
        margin: auto;

        &--prev{
            left: 0px;
        }
        &--next{
            right: 0px;
        }

        @include _Extra_large {
            
            display: none;
            
        }
    }

    &__container{
        height: 100%;
    }

    &__pagination{

        position: relative;
        justify-content: flex-end;
        
        z-index: 1;

        margin-top: -20px;
        margin-right: 45px;

    }

    .swiper{

        &-wrapper{

            height: 100%;

        }

    }

}

.categories-popular{

    height: fit-content;

    &__wrapper{

        display: grid;
        grid-template-columns: repeat(8,1fr);

        gap: 10px;

        @include _Extra_large {

            grid-template-columns: repeat(6,1fr);
            
        }

        @include _medium {
            grid-template-columns: repeat(4,1fr);
        }

        @include _small {

            grid-template-columns: repeat(2,1fr);
            
        }

    }

}

.categories-popular-card{

    height: 50px;

    background: #FFFFFF;
    border-radius: 4px;

    transition: all ease 400ms;

    padding: 8px 15px;

    &:hover{

        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);

    }

    &__wrapper{

        display: flex;

    }

    &__image{

        display: block;
        
        object-fit: contain;
        object-position: center;
        
        width: 100%;
        height: 100%;
        
        max-width: 100%;
        max-height: 100%;

    }

}

.margin-small{
    margin-bottom: 10px;
}

.svg-arrow-remind{

    &__cup{

        transform: translateX(1.7px);
        fill: white;

    }

}