$whiteIconZoom: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMzAgMzAiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yOS42IDI3LjhsLTYuNi02LjVjMi0yLjMgMy4yLTUuMiAzLjItOC40IDAtNy4xLTUuOS0xMi45LTEzLjEtMTIuOS03LjIgMC0xMy4xIDUuOC0xMy4xIDEyLjlzNS45IDEyLjkgMTMuMSAxMi45YzMgMCA1LjgtMSA4LTIuN2w2LjcgNi42Yy4zLjMuNS40LjkuNHMuNy0uMS45LS40Yy41LS42LjUtMS40IDAtMS45em0tMjctMTQuOGMwLTUuNyA0LjctMTAuMyAxMC41LTEwLjNzMTAuNSA0LjYgMTAuNSAxMC4zLTQuNyAxMC4zLTEwLjUgMTAuMy0xMC41LTQuNi0xMC41LTEwLjN6TTE4LjMgMTEuNWgtMy45di0zLjhjMC0uOC0uNS0xLjMtMS4zLTEuM3MtMS4zLjUtMS4zIDEuM3YzLjdoLTMuOGMtLjggMC0xLjMuNS0xLjMgMS4zcy41IDEuMyAxLjMgMS4zaDMuOHYzLjljMCAuOC41IDEuMyAxLjMgMS4zczEuMy0uNiAxLjMtMS4zdi0zLjloMy45Yy43IDAgMS4zLS41IDEuMy0xLjNzLS41LTEuMi0xLjMtMS4yeiIvPjwvc3ZnPg==);

.content-text {
  & > * {
    &:last-child {
      margin-bottom: 0px;
    }
  }

  h1 {
    margin-bottom: 20px;
    color: #333333;
    @include font-h1;
  }

  h2 {
    margin: calcFluid(30, 40) 0 14px;
    color: #333333;
    @include font-h2;
  }

  h3 {
    margin: calcFluid(30, 40) 0 14px;
    color: #333333;
    @include font-h3;
  }

  h4 {
    margin: calcFluid(30, 40) 0 10px;
    color: #333333;
    @include font-h4;
  }

  p {
    margin-bottom: calcFluid(12, 20);
    color: #333333;
    @include font-p;
  }

  img {
    display: block;
    margin: calcFluid(20, 35) 0;
    max-width: 100%;
    width: auto !important;
    height: auto !important;
    border-radius: $radius;
  }

  ul, ol {
    padding-left: 25px;
    margin: 0 0 35px;

    li {
      @include font-p;

      &:not(:last-child) {
        margin-bottom: calcFluid(9, 12);
      }
    }
  }

  ul {
    margin-top: 10px;
    padding-left: 20px;
    list-style: none;

    li {
      position: relative;

      &::before {
        --d: 7px;
        content: "";
        position: absolute;
        left: -20px;
        color: $primary;
        width: var(--d);
        height: var(--d);
        top: var(--d);
        background: $primary;
        border-radius: 50%;
        display: block;
      }
    }
  }

  table {
    width: auto !important;
    min-width: 100%;
    border-collapse: collapse;
    margin-top: 60px;
    --border: 1px solid #AAAAAA;
    border-radius: 14px;

    caption {
      font-weight: bold;
      font-size: calcFluid(16, 24);
      text-align: left;
      margin-bottom: 22px;
    }

    th, td {
      padding: calcFluid(8, 20);
      @include font-p;
      border: var(--border);
      border-top: none;
    }

    th {
      color: #000;
      font-weight: bold;
      text-align: left;
      border: 2px solid #AAAAAA;
      background: #EDEDED;

      &:last-child {
        border-right: 0;
      }
    }

    tr {
      border-bottom: var(--border);

      td:first-child {
        background: #EDEDED;
      }
    }

  }

  a {
    color: #FFAE00;
    @include font-p;
    border-bottom: 1px solid transparent;
    transition: border-color .2s;


    &:visited {
      color: $primary;
      border-color: transparent;
    }

    &:hover {
      border-color: #FFAE00;
    }
  }

  iframe {
    display: block;
    max-width: 100%;
    border: 0;
  }

  .table-wrap {
    margin: 18px 0 calcFluid(20, 35);
  }
}

.table-responsive {
  width: 100%;
  overflow: auto;
}

.table-wrap {
  border: 1px solid $border;
  border-radius: $radius;
}

// ----------------


.content-schema {
  background-color: #FAFAFA;
  padding: calcFluid(30, 70) 0;

  .content-text h2 {
    margin: 0 0 calcFluid(20, 40);
  }
}

.section-schema {
  padding: calcFluid(25, 55) 0;
  overflow: hidden;

  &__header {
    margin-bottom: calcFluid(20, 50);
    display: flex;
    align-items: center;
  }

  &__title {
    @include font-h2;
    font-weight: 700;
    color: var(--color-theme_text);
    margin-right: 15px;
  }
}

.schema-line {
  width: 2px;
  margin: 0 calcFluid(40, 70);
  flex-shrink: 0;
  background-color: transparent;
  position: relative;
  z-index: 0;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: var(--height);
    background-color: #333;
  }

  &__filling {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #333;
    height: 0;
    z-index: -2;
  }
}

.schema {
  &__body {
    display: grid;
    grid-template-columns: 1.4fr 0.2fr 1.4fr;

    &--mobile {
      display: none;
    }
  }

  &__part {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    height: 100%;

    &--left {
      .schema-step {
        &__ball {
          left: calcFluidP(26, 51);
        }

        &__wrap {
          &:after {
            left: calc(100% - 1px);
          }

          &:before {
            left: 100%;
          }
        }
      }
    }

    &--right {
      padding-top: calcFluid(50, 120);

      .schema-step {
        &__ball {
          right: calcFluidP(26, 51);
        }

        &__wrap {
          &:after {
            right: calc(100% - 1px);
            transform: rotate(180deg);
          }

          &:before {
            right: 100%;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.schema-step {
  position: relative;

  &:not(:last-child) {
    margin-bottom: calcFluid(35, 90);
  }

  &:last-child {
    margin-bottom: calcFluid(15, 50);
  }

  &__ball {
    width: calcFluid(30, 40);
    height: calcFluid(30, 40);
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #333;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    transition: .2s;
    z-index: 1;
    @include font-p;
    color: #333;
  }

  &__wrap {
    background: #fff;
    border: 1px solid #D6D6D6;
    padding: calcFluid(19, 40) calcFluid(10, 65);
    opacity: 0;
    position: relative;

    &:after, &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: calcFluid(25, 36);
      height: calcFluid(40, 52);
      margin: auto;
      border-left: calcFluid(25, 36) solid #fff;
      border-top: calcFluid(20, 26) solid transparent;
      border-bottom: calcFluid(20, 26) solid transparent;
      box-sizing: border-box;
    }

    &:before {
      border-left-color: #D6D6D6;
    }
  }

  &__title {
    @include font-h3;
    color: #333;
    display: block;
    margin-bottom: 7px;
  }

  &__text {
    @include font-p;
    color: #333;

    a {
      color: #333;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.schema-last-step {
  position: relative;
  z-index: 0;
  background-color: var(--color-theme);
  $this: &;

  &__ball {
    width: calcFluid(30, 40);
    height: calcFluid(30, 40);
    margin: 0 auto calcFluid(15, 35);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main);
    background-color: #fff;
    border-radius: 50%;
    transition: .3s;
    z-index: 1;
    @include font-p;
    color: var(--main);

    &.active {
      background-color: var(--main);
      color: #fff;
    }
  }

  &__wrap {
    border: 1px solid #D6D6D6;
    background-color: #fff;
    padding: calcFluid(15, 35) calcFluid(15, 40);
    max-width: 385px;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    opacity: 0;
    text-align: center;

    &:after, &:before {
      content: none;
    }
  }

  &__title {
    @include font-h3;
    color: #333;
    display: block;
    margin-bottom: 15px;

    & .show_mobile {
      display: none;
    }
  }

  &__text {
    @include font-p;
    color: #333;
    display: block;
    margin-bottom: 20px;

    a {
      color: var(--main);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF !important;
    background-color: var(--main);
    width: 100%;
    height: 55px;
    margin: 20px 0 0;
    transition: .2s;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  &.show {
    #{ $this }__ball {
      background-color: var(--main);
      color: #fff;
    }
  }
}

// ------------------

.content__block {
  margin: calcFluid(22, 35) 0;
}


.content__files {
  display: grid;

  &.column-5 {
    grid-template-columns: repeat(5, 1fr);
    @include _container {
      grid-template-columns: repeat(4, 1fr);
    }
    @include _large {
      grid-template-columns: repeat(3, 1fr);
    }
    @include _medium {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _extra_small {
      grid-template-columns: 1fr;
    }
  }

  &.column-4 {
    grid-template-columns: repeat(4, 1fr);
    @include _large {
      grid-template-columns: repeat(3, 1fr);
    }
    @include _medium {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _extra_small {
      grid-template-columns: 1fr;
    }
  }

  &.column-3 {
    grid-template-columns: repeat(3, 1fr);
    @include _medium {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _extra_small {
      grid-template-columns: 1fr;
    }
  }

  &.column-2 {
    grid-template-columns: repeat(2, 1fr);
    @include _extra_small {
      grid-template-columns: 1fr;
    }
  }
}

.content-file {
  padding: calcFluid(15, 35);
  color: $text;
  border-radius: 3px;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #AAAAAA;
  border-radius: 14px;
  transition: .15s;
  $this: &;

  &__name {
    display: block;
    margin-bottom: calcFluid(15, 55);
    color: #000;
    font-weight: 700;
    font-size: calcFluid(16, 24);
  }

  &__info {
    line-height: 22px;
    font-weight: 400;
    font-size: 18px;
    color: #000;

    svg {
      width: 28px;
      height: 33px;
      margin-right: calcFluid(8, 20);
      fill: #A7A7A7;
      transition: .15s;
    }
  }

  &:hover {
    background: #F3F3F3;
    transition: .3s;

    #{$this}__info {

      svg {
        fill: $primary;
        transition: .3s;
      }
    }
  }
}

.content__gallery {
  display: grid;
  padding-bottom: 40px;

  &--6 {
    grid-template-columns: repeat(2, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.gallery-item {
  $this: &;

  &__thumbnail {
    display: block;
    position: relative;
    // padding-bottom: 68.9%;
    background: center/cover no-repeat #F1F7FA;
    border-radius: $radius;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $whiteIconZoom center/30px 30px no-repeat var(--main-lighter);
      opacity: 0;
      transition: opacity $tr-time;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &--cert {
      padding-bottom: 69%;
      background-position: center top;
    }
  }

  &__caption {
    margin-top: calcFluid(8, 10);
    color: #000;
    font-size: calcFluid(12, 18);
    line-height: calcFluid(16, 22);
    font-weight: 400;
    text-align: center;
  }

  &--6 {
    flex-basis: calc(50% - #{$gap*2});
  }

  &--4 {
    flex-basis: calc(33.33% - #{$gap*2})
  }

  &--3 {
    flex-basis: calc(25% - #{$gap*2})
  }
}

.content-quote {
  padding: calcFluid(16, 40) calcFluid(20, 60);
  background: #F3F3F3;
  border-radius: 8px;
  color: #000;
  font-weight: 400;
  font-size: 18px;

  p:not(:last-child) {
    margin-bottom: 20px;
  }
}

.content__subpages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  &--2 {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
  }

  &--1 {
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }

  > :last-child, > .content-text:last-child > :last-child {
    margin-bottom: 0 !important;
  }
}

.subpage-vertical {
  border: 1px solid $border;
  transition: color $tr-time, border-color $tr-time, box-shadow $tr-time;

  &__info {
    padding: calcFluid(18, 30) calcFluid(18, 35);

    &--min-height {
      min-height: calcFluid(120, 150);
    }
  }

  &__img {
    border-radius: 0 !important;
  }

  &__title {
    font-weight: 700;
  }

  &:hover {
    .news-block__title {
      color: var(--main);
    }

    color: var(--main);
    border-color: transparent;
  }
}

.subpage-horizontal {
  display: flex;
  min-height: 230px;

  &--small {
    min-height: 153px;
  }

  &__img {
    flex: 0 0 26.7%;
    padding: 0;
    border-radius: 0 !important;

    &--small {
      flex-basis: 32.35%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: calcFluid(25, 35) calcFluid(20, 30);
    flex-grow: 1;

    &--small {
      padding: calcFluid(15, 25) calcFluid(18, 35);
    }
  }

  &__link {
    margin-top: auto;
    padding-top: 12px;
    @include font-p;
    color: var(--main);
    transition: color $tr-time;

    svg {
      width: 13px;
      height: 8px;
      margin-left: 10px;
      fill: var(--main);
      transition: transform $tr-time;
    }
  }

  &__text {
    margin-top: calcFluid(12, 20);
    color: #929292;
  }

  &__title {
    color: $text;
  }

  $this: &;

  &:hover {
    #{$this}__link {
      color: $text;

      svg {
        transform: translateX(15px);
      }
    }
  }
}


.sidebar-menu {
  border-left: 2px solid $border;
  background: #F3F3F3;
  border-radius: 8px;
  position: sticky;
  top: 90px;
  padding: 40px;

  &__link {
    display: block;
    margin-left: -2px;
    border-left: 4px solid transparent;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    padding: 18px 0;
    border-bottom: 1px solid #AAAAAA;
    transition: .15s;

    &--active {
      color: #FFD300;
      transition: .3s;
    }

    &:hover {
      color: #FFAE00;
      transition: .3s;
    }
  }

}

.quiz {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: calcFluid(30, 70) 0;

  &-part {
    flex: 50%;
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 90px;

    p {
      @include font-h2;
      color: #333333;
    }

    svg {
      width: 280px;
      height: 90px;
      align-self: flex-end;
    }
  }

  &-container {
    border: 1px solid #D6D6D6;
  }

  &-navigator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 23px 40px;
    border-bottom: 1px solid #F5F5F5;

    &__back {
      position: relative;
      padding-left: 15px;
      cursor: pointer;
      opacity: 1;
      visibility: visible;
      transition: .5s;

      &.disabled {
        opacity: 0;
        visibility: hidden;
      }

      &:hover span {
        text-decoration: none;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        text-decoration: underline;
        color: #333333;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: transparent;
        border-left: 1px solid #333333;
        border-top: 1px solid #333333;
        transform: rotate(-45deg);
        left: 0;
        top: calc(50% - 3px);
      }
    }

    &__page {
      span {
        font-size: 18px;
        line-height: 24px;
        color: #CCCCCC;
      }
    }

    &__next {
      padding: 0 40px 35px;

      button {
        padding: 15px 60px;
        background-color: var(--main);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
        transition: .2s;

        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &-body {
    position: relative;
    overflow: hidden;
    transition: all 500ms cubic-bezier(0.580, 0.230, 0.380, 0.795);
    transition-timing-function: cubic-bezier(0.580, 0.230, 0.380, 0.795);
  }

  &-step {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    transition: all 500ms cubic-bezier(0.580, 0.230, 0.380, 0.795);
    transition-timing-function: cubic-bezier(0.580, 0.230, 0.380, 0.795);
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &__title {
      padding: 15px 40px;
      background-color: #F5F5F5;

      span {
        @include font-h3;
        color: #333333;
      }
    }

    &__content {
      padding: 20px 40px 28px;
    }

    &__question {
      padding: 0 0 20px;

      span {
        @include font-h4;
        color: #333333;
      }
    }

    &__answers {
      display: flex;
      flex-direction: column;
    }

    &__radio {
      $this: &;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      &:not(:last-child) {
        margin: 0 0 14px;
      }

      &-input {
        display: none;

        &:checked {
          + #{$this}-marker {
            background-color: var(--main);
            border-color: var(--main);
          }
        }
      }

      &-marker {
        position: relative;
        display: block;
        width: 22px;
        min-width: 22px;
        height: 22px;
        background-color: #fff;
        border: 1px solid #CCCCCC;
        margin-right: 15px;
        border-radius: 50%;
        transition: .2s;

        &::after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          background-color: #fff;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border-radius: 50%;
          margin: auto;
        }
      }

      &-text {
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }
    }
  }
}

.content-seo {
  margin: calcFluid(30, 70) 0;
}

.content-catalog {
  margin: calcFluid(30, 70) 0;

  .content-text h2 {
    margin: 0 0 calcFluid(20, 40);
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px 30px;
    grid-auto-flow: row;
  }

  &__block {
    $this: &;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 2px solid #EAEAEA;

    &:hover {
      #{$this}--title {
        span {
          color: #fff;
        }

        &::before {
          width: 100%;
          height: 100%;
        }
      }
    }

    &--title {
      padding: 45px 40px 0;
      height: 150px;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 40px;
        height: 15px;
        position: absolute;
        top: 0;
        left: 0;
        transition-duration: .2s;
        background-color: var(--main);
      }

      span {
        @include font-h3;
        color: #333333;
        position: relative;
        z-index: 1;
        transition-duration: .2s;
      }
    }

    &--thumb {
      max-height: 260px;
      height: 260px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.content-map {
  position: relative;

  .contact-feedback {
    left: 25vw;
    top: 25%;
  }
}

.content-subscribe {
  display: flex;
  flex-direction: row;
  max-height: 320px;
  margin: calcFluid(30, 70) 0;

  &__content,
  &__image {
    flex-basis: 50%;
    max-height: 320px;
  }

  &__title {
    padding: calcFluid(15, 45) calcFluid(45, 145) calcFluid(15, 45) 0;

    &--icon {
      margin: -7px 18px 0px 0;
      fill: var(--main);
    }

    .content-text {
      margin-bottom: 40px;

      h2 {
        margin-top: 0;
      }
    }

    .form-text-input {
      height: 55px;
      border-radius: 0;
    }

    .btn--filled {
      border-radius: 0;
    }
  }

  &__image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.content-heading {
  margin: calcFluid(30, 70) 0;

  &__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;
    row-gap: 32px;
  }

  .news-block {
    border: 1px solid #D6D6D6;
    height: 100%;
    transition: $tr-time;
    border-radius: 0px;

    &:hover {
      border-color: transparent;
    }
  }

  .news-block__img {
    border-radius: 0px !important;
  }

  .content-text {
    margin-bottom: 40px;

    h2 {
      margin-top: 0;
    }
  }
}

.content-slider {
  margin-bottom: calcFluid(30, 75);

  .main-slider__container {
    background-color: transparent;
    border-radius: 0;
  }

  .main-slider__title {
    @include font-h2;
  }

  .main-slider__text {
    @include font-p;
  }

  .main-slider__slide-bg {
    position: absolute;
    right: 0;
    width: 45vw;
    height: 100%;
  }
}

.content-advantages {
  margin: calcFluid(30, 70) 0;

  .content-text h2 {
    margin: 0 0 calcFluid(20, 40);
  }
}

.content-form {
  margin: calcFluid(30, 70) 0;
}

@media (min-width: 1200px) {
  .text-page {
    &__content {
      width: getNColumnsPercentWidth(12);
    }

    &__sidebar {
      width: getNColumnsPercentWidth(3);
    }
  }
}

@media (max-width: 1199px) {
  .text-page {
    display: block;

    &__sidebar {
      margin-top: calcFluid(30, 50);
    }
  }
}

@media (max-width: 991px) {
  .subpage-horizontal {
    &--small {
      display: block;
    }

    &__img--small {
      padding-bottom: 62%;
    }
  }
  .content-heading__wrap {
    grid-template-columns: repeat(3, 1fr);
  }
  .content-slider .main-slider__slide-bg {
    width: 30vw;
  }
  .quiz-title {
    padding: 0;
  }

  .content-catalog__wrap {
    gap: 15px;
  }
  .content-catalog__block--title {
    padding: 20px;
    height: 110px;
  }
  .content-catalog__block--title::before {
    width: 30px;
    height: 8px;
  }
  .content-catalog__block--thumb {
    height: 150px;
  }
}

@media (max-width: 767px) {
  .content__subpages {
    row-gap: 20px;
  }
  .subpage-horizontal {
    display: block;

    &__img {
      padding-bottom: 62%;
    }
  }
  .content {
    &__files {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }
  .content-subscribe {
    flex-direction: column;
    max-height: unset;

    &__title {
      padding: calcFluid(0, 15) calcFluid(0, 45) calcFluid(0, 15) 0;

      .content-text {
        margin-bottom: 20px;
      }
    }

    &__image {
      margin-top: 20px;
      height: calcFluid(200, 320);
      max-height: calcFluid(200, 320);
    }
  }
  .content-heading__wrap {
    grid-template-columns: repeat(2, 1fr);
  }
  .content-slider .main-slider__slide-bg {
    left: 0;
    width: 100%;
    top: 0;
    z-index: -1;
    filter: brightness(0.5);
  }

  // ------------------------
  .quiz {
    flex-direction: column;
  }
  .quiz-part {
    width: 100%;
  }
  .quiz-title {
    margin: 0 0 20px;
  }
  .quiz-title svg {
    display: none;
  }
  .quiz-navigator {
    padding: 10px 15px;
  }
  .quiz-step__title {
    padding: 8px 15px;
  }
  .quiz-step__content {
    padding: 15px 15px 20px;
  }
  .quiz-navigator__next {
    padding: 0 15px 15px;
  }
  .quiz-navigator__next button {
    padding: 15px 0;
    width: 100%;
  }

  // ------------------------
  .schema__body {
    display: none;
  }
  .schema__body--mobile {
    display: block;
  }

  .schema-step__wrap:after,
  .schema-step__wrap:before {
    display: none;
  }
  .schema-step:not(:last-child),
  .schema-step:last-child {
    margin-bottom: 15px;
  }

  .schema-last-step__title .show_mobile {
    display: inline-block;
  }
  .schema-last-step__ball {
    display: none;
  }
  .schema-last-step__wrap {
    max-width: 100%;
    text-align: left;
  }
  .schema-last-step__button {
    width: 230px;
    height: 45px;
  }
}

@media (max-width: 575px) {
  .content {
    &__subpages {
      grid-template-columns: 1fr;

      &--2 {
        grid-template-columns: 1fr;
      }
    }

    &__gallery {
      &--6, &--4, &--3 {
        grid-template-columns: 1fr;
      }
    }
  }
  .content-heading__wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  .content-catalog__wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .content-catalog__wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.content-twl {
  margin-bottom: 40px;

  &__title {
    font-weight: 900;
    font-size: 24px;
    color: #000;
    padding-bottom: 18px;
    border-bottom: 2px solid $primary;
  }

  &__description {
    font-weight: 400;
    font-size: 18px;
    color: #000;
    padding-top: 18px;
  }
}

.banner-element {
  margin-top: calcFluid(10, 55);
  border: 1px solid #AAAAAA;
  border-radius: 8px;
  padding: calcFluid(15, 50) calcFluid(20, 95) calcFluid(15, 60);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include _Extra_large_ {
    padding: 50px 75px 60px;
  }

  &__title {
    font-weight: 900;
    font-size: calcFluid(18, 48);
    color: #000000;
    margin-top: 5px;
  }

  &__description {
    font-size: calcFluid(12, 18);
    max-width: 778px;
    margin-top: 5px;
  }
}