// Базовые классы
@import 'base/base_bundle';

@import 'swiper/swiper';
@import 'lightgallery/lightgallery';

@import 'components/main-slider';
@import 'components/index-block';
@import 'components/index-products';
@import 'components/seo-block';
@import 'components/product-cards';
@import 'components/news-block';
@import 'components/partners';
@import 'components/advantages';
@import 'components/text-page';
@import 'components/block-title';
@import 'components/category-block';
@import 'components/article';
@import 'components/popup';
@import 'components/action-1';
@import 'components/action-2';
@import 'components/index-categories';
@import 'components/go-to-all';
@import 'components/reviews';
@import 'components/tabs';
@import "components/page-title";
@import "components/index-advantages";
@import "components/feedback";
@import "components/index-clients";
@import "components/index-blog";
@import "components/index-feedback";
@import "components/index-about";
@import "components/about-footer";
@import "components/components";


.roundabout-holder {
  padding: 0;
  margin: 0 auto;
  width: 650px !important;
  height: 430px !important;
  @include _mobile {
    width: calcFluid(650/3, 650) !important;
    height: calcFluid(430/3, 430) !important;
  }
}


.roundabout-moveable-item {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  //width: 500px;
  //height: 320px;
  //@include _mobile {
  //  width: calcFluid(500/3, 500);
  //  height: calcFluid(320/3, 320);
  //}
}

.roundabout-moveable-item img {
  height: 100%;
  width: 100%;
}

.roundabout-in-focus {
  cursor: auto;
}


#carousel li {
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  opacity: 1 !important;
}

#carousel li.roundabout-in-focus:hover span {
  display: block;
  z-index: 1;
}

#carousel li img {
  opacity: 0.65;
  border-radius: 14px;
}

#carousel li.roundabout-in-focus {
  cursor: default;
}

#carousel li.roundabout-in-focus img {
  opacity: 1 !important;
}

#carousel li span {
  display: none;
  position: absolute;
  width: 260px;
  border-radius: 5px;
  margin: 270px 88px;
  overflow: hidden;
}
