.main-slider {

  $this: &;

  position: relative;
  border-radius: $radius;

  &_banner {

    max-width: 100%;
    // margin: 0 !important;

    .container {

      @include _Extra_large {

        padding: 0 !important;

      }

    }

    #{$this} {

      &__arrow {


      }

      &__wrapper-text {

        @include _Extra_large_ {

          padding: 0 100px;

        }

      }

      &__wrapper-arrow {

        display: flex;
        justify-content: space-between;

        position: absolute;

        transform: translateY(-50%) translateX(-50%);
        left: 50%;
        top: 50%;

        z-index: 10;

      }

      &__controls {

        @include _Extra_large_ {

          margin-top: 25px;

        }

      }

    }

  }

  &__container {
    background: #B6B6B6;
    border-radius: $radius;
  }

  &__slide {
    // height: 500px;
    padding: 0 88px;
    height: calc(38.4vw - 30px);
    max-height: 500px;
  }

  &__title {

    margin-bottom: 15px;
    font-size: 42px;
    line-height: 54px;

    font-weight: 700;

    @include _max {

      margin-bottom: calcFluid(12, 15);
      font-size: calcFluid(27, 42);
      line-height: calcFluid(33, 54);

    }

  }

  &__text {

    font-size: 16px;
    line-height: 24px;

    @include _max {

      font-size: calcFluid(13, 16);
      line-height: calcFluid(19, 24);

    }

  }

  &__title, &__text {
    max-width: 470px;
  }

  &__link {
    margin-top: 28px;
  }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }

    &--static {
      position: static;
      margin: 0;
    }
  }

  &__pagination {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 1;

    &--static {
      position: static;
    }
  }

  &__controls {
    &--offset {
      margin-top: 9px;
    }
  }

  &--white {
    color: #ffffff;
  }
}

.slider-arrow {
  width: 30px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: $radius;
  transition: box-shadow $tr-time, background-color $tr-time;
  cursor: pointer;

  svg {
    width: 12px;
    height: 8px;
    transform: rotate(90deg);
    fill: $text;
  }

  &--next {
    order: 1;

    svg {
      transform: rotate(-90deg);
    }
  }

  &--small {
    height: 42px;
  }

  &:hover {
    background: var(--main);
    box-shadow: none;

    svg {
      fill: #fff;
    }
  }
}

.slider-pagination {
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: $border;
    border-radius: 50%;
    transition: background-color $tr-time;

    &-active {
      background: var(--main);
    }
  }
}

@media (max-width: 1400px) {
  // .main-slider{
  //     width: 80%;
  //     display: block;
  //     margin: 0 auto;
  //     &__slide{
  //     max-height: 350px;
  //     }
  // }
}

@media (min-width: 1200px) {
  .main-slider {
    &--border {
      border: 1px solid #B6B6B6;
    }
  }
}

@media (max-width: 1199px) {
  .main-slider {
    &__slide {
      // height: calcFluid(250,500);
      padding: 0 35px;
    }

    &__arrow {
      display: none;
    }

    &__pagination {
      position: static;
    }

    &__controls {
      margin-top: 18px;
      justify-content: center;
    }
  }
}

@media (max-width: 767px) {
  .main-slider {
    &__slide {
      padding: 20px;
      justify-content: flex-start;
    }
  }
}

@media (max-width: 567px) {
  .main-slider {
    &__slide {
      // height: auto;
    }
  }
}


//МАКЕТ СЛАЙДЕР
.section-slider {
  background: black;
  overflow: hidden;
  position: relative;
  //min-height: 784px;
  @include _small {
    min-height: 432px;
  }

  .section-slide__wrapper {
    margin-bottom: 80px;
    @include _small {
      margin-top: 172px;
      margin-bottom: 0;
    }
  }

  &-arrows {
    position: absolute;
    left: 90px;
    right: 90px;
    display: flex;
    justify-content: space-between;
    top: calc(50% - 46px);
    z-index: 1;
    padding: 0 calcFluid(3, 15);
    @include _container {
      left: 0;
      right: 0;
    }
    @include _small {
      top: auto;
      bottom: 145px;
    }
  }

  &-arrow {
    width: calcFluid(24, 48);
    height: calcFluid(24, 48);
    border-radius: calcFluid(4, 8);
    border: 1px solid #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .15s background-color;
    cursor: pointer;

    &.right {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      width: 8px;
      height: calcFluid(8, 16);
      fill: #FFF;
    }

    &:hover {
      background: #FFF;
      transition: .3s background-color;

      svg {
        fill: #000;
      }
    }
  }

  &-pagination {
    display: flex;
    margin: calcFluid(-3, -5);
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: calcFluid(13, 39);
    z-index: 1;
  }

  &-item {
    &__wrapper {
      display: flex;
      align-items: center;
      height: calcFluid(555, 785);
      padding-left: calcFluid(50, 100);
      background-position: right bottom;
      background-repeat: no-repeat;
      position: relative;
      z-index: 1;
      @include _medium {
        padding-left: 0;
        height: auto;
      }
      @include _small {
        background-position: center top;
        background-size: contain;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(90deg, rgb(0, 0, 0) 8.54%, rgba(0, 0, 0, 0) 100.52%);
        z-index: -1;
        @include _small {
          background: linear-gradient(0deg, #000 64.54%, rgba(0, 0, 0, 0) 100.52%);
        }
      }
    }

    &__title {
      font-weight: 900;
      font-size: calcFluid(20, 74);
      text-transform: uppercase;
      color: #FFF;
      max-width: 1072px;
      user-select: none;
      cursor: default;
      letter-spacing: 0.02em;
      line-height: calcFluid(24, 82);
      @include _medium {
        text-align: center;
      }
    }

    &__subtitle {
      font-size: calcFluid(12, 16);
      line-height: calcFluid(15, 20);
      color: #FFF;
      max-width: 555px;
      user-select: none;
      cursor: default;
      margin-top: calcFluid(15, 20);
      @include _medium {
        text-align: center;
        padding: 0 30px;
      }
    }

    &__btn {
      margin-top: calcFluid(18, 55);
      @include _medium {
        width: fit-content;
        padding: 10px 60px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
      }
    }
  }

  .swiper-pagination-bullet {
    display: block;
    border-radius: 50%;
    margin: calcFluid(3, 5);
    width: calcFluid(8, 12);
    height: calcFluid(8, 12);
    background: rgba(184, 184, 184, .4);
    transition: .15s;

    &-active {
      background-color: $primary;
    }
  }
}