
.feedback {
  background: #000;
  @include _medium {
    background-image: url("/static/images/feedback_logo.png");
    background-repeat: no-repeat;
    background-position: top 16px center;
    background-size: contain;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 280px;
    background-image: url("/static/images/feedback_logo.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    padding-left: calcFluid(0, 110);
    padding-top: 25px;
    padding-bottom: 35px;
    max-width: 1510px;
    @include _medium {
      background: none;
      padding-top: 36px;
    }
    @include _Extra_large_ {
      padding-left: 110px;
    }
    @include _mobile_ {
      padding-left: 35px;
    }

  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calcFluid(500, 630, 0, 1280, 1920);
    width: 100%;
    @include _mobile {
      max-width: 500px;
    }

    &__title {
      color: #FFF;
      font-weight: 900;
      font-size: calcFluid(35, 42, 0, 1280, 1920);
      letter-spacing: 0.02em;
      margin-bottom: 10px;
      @include _mobile {
        font-size: calcFluid(18, 42);
      }
      @include _medium {
        text-align: center;
        margin-bottom: 15px;
      }
    }

    &__description {
      font-weight: 400;
      font-size: calcFluid(12, 18);
      color: #FFF;
      margin-bottom: calcFluid(10, 20);
      @include _medium {
        text-align: center;
      }
    }
  }

  &-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
    @include _medium {
      align-items: center;
    }

    .ps-btn {
      max-width: 296px;
      width: 100%;
      @include _medium {
        font-size: 14px;
        padding: 10px;
      }
    }

    &__input {
      position: relative;
      padding: 0 15px 5px;

      display: flex;
      flex-direction: column;
      align-items: center;

      &.full-width {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }

      &.min-width .ps-input-label {
        position: relative;
        top: -5px;
        left: 0;
      }

      @include _medium {
        &.email {
          padding: 16px 30px;
          margin: 10px 0;
        }
        font-size: 12px;
      }

      @include _medium {
        padding: 5px 0;

        .ps-input {
          max-width: 100%;
        }

        &.error {
          padding-bottom: 10px;
        }
      }
    }

    @include _medium {
      align-items: flex-start;
    }

    &__row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: -6px;

      position: relative;
      @include _medium {
        flex-direction: column;
        align-items: center;
        margin: 0;
      }

      button, input  { // &.edits div
        margin: 0 7px;
        @include _medium {
          width: 240px !important;
          max-width: 100%;
        }
      }
    }

    &__footer {
      font-weight: 400;
      font-size: calcFluid(12, 15);
      color: #FFF;
      margin-top: calcFluid(6, 11);
      text-align: right;
      width: 100%;
      @include _medium {
        text-align: center;
      }

      a {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: underline;
        transition: .15s;

        &:hover {
          transition: .3s;
          text-decoration: none;
        }
      }
    }
  }
}