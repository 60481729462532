@mixin font-h1 {
  font-weight: 700;
  font-size: calcFluid(27, 42);
  line-height: calcFluid(33, 54);

}

@mixin font-h2 {
  font-weight: 700;
  font-size: calcFluid(20, 36);
  line-height: calcFluid(26, 44);
}

@mixin font-h3 {
  font-weight: 500;
  font-size: calcFluid(18, 20);
  line-height: calcFluid(20, 22);
}

@mixin font-h4 {
  font-weight: 700;
  font-size: calcFluid(13, 18);
  line-height: calcFluid(24, 22);
}

@mixin font-p {
  font-size: calcFluid(14, 18);
  line-height: calcFluid(18, 24);
}


// Адаптив
@mixin _max {
  @media (max-width: 1919px) {

    @content;

  }
}

@mixin _max_ {
  @media (min-width: 1920px) {

    @content;

  }
}

@mixin _container {
  @media (max-width: $containerWidth - 1px) {

    @content;

  }
}

@mixin _container_ {
  @media (min-width: $containerWidth) {

    @content;

  }
}
@mixin _mobile {
  @media (max-width: 1279px) {
    @content
  }
}
@mixin _mobile_ {
  @media (min-width: 1279px) {
    @content
  }
}
@mixin _Extra_large {
  @media (max-width: 1199px) {

    @content;

  }
}

@mixin _Extra_large_ {
  @media (min-width: 1200px) {

    @content;

  }
}


@mixin _large {
  @media (max-width: 991px) {

    @content;

  }
}

@mixin _large_ {
  @media (min-width: 992px) {

    @content;

  }
}


@mixin _medium {
  @media (max-width: 767px) {

    @content;

  }
}

@mixin _medium_ {
  @media (min-width: 768px) {

    @content;

  }
}


@mixin _small {
  @media (max-width: 575px) {

    @content;

  }
}

@mixin _small_ {
  @media (min-width: 576px) {

    @content;

  }
}

@mixin _extra_small {
  @media (max-width: 420px) {

    @content;

  }
}