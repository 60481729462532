#REVIEW_CONTAINER{
    &.loading{
        opacity: .5;
        pointer-events: none;
        user-select: none;
    }
}

.review-container{
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    &:not(:last-child){
        margin: 0 0 30px;
        @include _medium{
            margin: 0 0 15px;
        }
    }
    @include _medium{
        grid-row-gap: 15px;
    }
    &--grid-1{
        grid-template-columns: repeat(1, 1fr);
    }
    &--grid-2{
        grid-template-columns: repeat(2, 1fr);
        @include _large{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &--grid-3{
        grid-template-columns: repeat(3, 1fr);
        @include _medium{
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 15px;
        }
        @include _extra_small{
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.review{
    &-more{
        display: flex;
        justify-content: center;
        margin: 20px 0 0;
        button{
            padding: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            border: none;
            background: none;
            cursor: pointer;
            transition: $tr-time;
            &:hover{
                color: var(--main);
            }
        }
    }
}

.review-sort{
    margin: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include _small{
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    &__params{
        display: flex;
        flex-direction: row;
        @include _large{
            flex-direction: column;
        }
        &-title{
            font-weight: 700;
            font-size: 15px;
            line-height: 24px;
            color: #929292;
        }
    }
    &__label{
        margin: 0 15px;
        @include _large{
            margin: 0;
        }
        input{
            display: none;
            &:checked + span{
                color: #333333;
            }
        }
        &-name{
            font-size: 15px;
            line-height: 24px;
            color: #929292;
        }
    }
    &__add{
        @include _small{
            margin: 0 0 15px;
        }
        a{
            padding: 10px 30px;
        }
    }

    .filter{
        margin-left: 30px;
        @include _large{
            margin: 10px 0 0;
        }
    }
}

.review-block{
    &.opened{
        z-index: 1;
    }
    &__wrapper{
        background: #FFFFFF;
        border: 1px solid #E2E3E7;
        padding: 30px 40px;
        min-height: 100%;
        &:not(:last-child){margin: 0 0 20px;}
        @include _large{
            padding: 15px;
            &:not(:last-child){margin: 0 0 15px;}
        }
    }
    &__top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 10px;
        @include _small{
            flex-direction: column;
        }
    }
    &__preview{
        display: flex;
        flex-direction: row;
        @include _small{
            margin: 10px 0 0;
        }


        &--one{
            margin: 0 0 10px;
        }


        &-item{
            width: 85px;
            height: 55px;
            margin: 0 3px;
            @include _small{
                width: 75px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &--next{
                position: relative;
                span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: linear-gradient(0deg, rgba(50, 176, 169, 0.5), rgba(50, 176, 169, 0.5));
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    color: #FFFFFF;
                }
            }
            &--hidden{
                display: none;
            }
        }
    }
    &__person{
        display: grid;
        grid-template-columns: 70px 1fr;
        margin: 0 0 10px;
        @include _small{
            grid-template-columns: 50px 1fr;
        }
        &-avatar{
            background: #E1E1E1;
            border-radius: 100%;
            width: 50px;
            height: 50px;
            overflow: hidden;
            .no-photo{
                width: 100%;
                height: 100%;
                fill: #D0D0D0;
            }
            @include _small{
                width: 40px;
                height: 40px;
            }
        }
        &-name{
            p{
                margin: 5px 0 0;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #333;
                @include _small{
                    font-size: 14px;
                    line-height: 18px;
                }
            }
            span{
                font-size: 14px;
                line-height: 24px;
                color: #333333;
                @include _small{
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
    &__text{
        font-size: 15px;
        line-height: 20px;
        color: #333333;
        @include _small{
            font-size: 12px;
            line-height: 16px;
        }
        a{
            display: inline-block;
            color: #32B0A9;
            border-bottom: 1px dashed #32B0A9;
            margin: 10px 0 0;
            &.opened{
                color: #929292;
                border-color: #929292;
            }
        }
        span{
            &.hidden{
                &.opened{
                    display: inline !important;
                }
            }
        }
    }
    &__scan{
        display: flex;
        align-items: center;
        margin: 18px 0 0;
        svg{
            width: 16px;
            height: 21px;
            fill: #333333;
            margin-right: 15px;
        }
        span{
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
            transition: $tr-time;
            @include _small{
                font-size: 14px;
                line-height: 18px;
            }
        }
        &:hover{
            span{color: var(--main);}
            svg{fill: var(--main);}
        }
    }
    &__video{
        margin: 18px 0 0;
        display: block;
        width: 240px;
        height: 130px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.6);
        }
        &::before, &::after{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            transition: $tr-time;
        }
        &::before{
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 0 8px 12px;
            border-color: transparent transparent transparent #000000c7;
            left: 3px;
            z-index: 1;
        }
        &::after{
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: #ffffff;
        }
        &:hover{
            &::before, &::after{
                transform: scale(1.2);
            }
        }
    }
}

.review-empty{
    display: grid;
    grid-template-columns: 55px 1fr;
    align-items: center;
    &__icon{
        svg{
            width: 38px;
            height: 38px;
            fill: #DBDBDB;
        }
    }
    &__content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    &__text{
        &-p{
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: #333333;
            margin: 0 0 4px;
        }
        &-span{
            font-size: 16px;
            line-height: 20px;
            color: #B6B6B6;
        }
    }
    &__button{
        a{padding: 10px 30px;}
    }
}

.review-modal{
    position: relative;
    width: 700px;
    margin: 0 auto;
    background-color: #fff;
    @include _large{
        width: calc(100% - 30px);
    }
    &__container{
        padding: 60px;
        @include _small{
            padding: 25px;
        }
        &.load{
            opacity: .4;
            pointer-events: none;
            user-select: none;
        }
    }
    &__head{
        margin: 0 0 25px;
        &-title{
            display: flex;
            align-items: center;
            margin: 0 0 12px;
        }
        &_desc{
            font-size: 16px;
            line-height: 22px;
            color: #333333;
        }
    }
    &__title{
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #333333;
    }
    &__body{
        &-item{
            &:not(:last-child){
                margin: 0 0 16px;
            }
        }
    }
    &__footer{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 25px 0 0;
        @include _small{
            margin: 15px 0 0;
        }
        @include _small{
            flex-direction: column;
            align-items: flex-start;
        }
        button{
            padding: 14px 45px;
            margin-right: 20px;
            @include _small{
                margin: 0 0 10px;
            }
        }
        p{
            font-size: 13px;
            line-height: 18px;
            color: #7D7D7D;
            a{
                text-decoration: underline;
                color: #7D7D7D;
            }
        }
    }
}

.review-rate{
    svg{
        cursor: pointer;
        &.star{
            stroke: #FFCA64;
            fill: transparent;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            @include _small{
                width: 15px;
                height: 15px;
            }
        }
        &.fill, &.fill--temp{
            fill: #FFCA64;
        }
    }

    &--active{
        &:hover{
            fill: #FFCA64;
        }
    }
}

.review-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 16px 0;
    border-bottom: 1px solid #eaeaea;
    &__rate{
        display: flex;
        flex-direction: row;
        align-items: center;
        &-icon{
            width: 14px;
            height: 14px;
            fill: #ACADAE;
            margin: 0 10px;
        }
        span{
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: #ACADAE;
        }
    }
    &__trigger{
        font-size: 14px;
        line-height: 24px;
        color: #929292;
        a{
            text-decoration: underline;
            color: #333;
            margin-left: 5px;
            &:hover{
                color: var(--main);
            }
        }
    }
}