.mobile-menu {
  width: 100%;
  position: fixed;
  top: 80px;
  border-top: 1px solid #D6D6D6;
  bottom: 0;
  display: none;
  pointer-events: none;
  z-index: 500;
  $this: &;
  &.opened {
    display: block;
  }
  .menu-item--has-children .menu-item__arrow {
    display: flex;
  }

  .menu-item__arrow {
    display: none;
  }

  &__back {
    border: 1px solid #D6D6D6;
    border-top: none;
    margin-bottom: 7px;

    > a {
      color: #000;
      padding: 20px 15px !important;
      justify-content: flex-start !important;
      font-size: 12px !important;
    }

    svg {
      margin-right: 11px;
      fill: #000;
    }
  }

  &__wrap {
    width: 100%;
    min-height: 100%;
    position: relative;
    z-index: 5;
    left: 100%;
    background: #ffffff;
    transition: transform $tr-time;
  }

  &__link {
    display: block;
    font-size: calcFluid(14, 16);
    line-height: 1.15;
    color: $text;

    &:not(:last-child) {
      margin-bottom: calcFluid(20, 25);
    }
  }

  &__catalog {
    list-style: none;
    padding-top: 10px;

    .menu-item {
      > a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 14px;
        color: #000;
        font-size: 14px;
        line-height: 20px;
      }
    }

  }

  &__sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    transition: transform $tr-time;

    &.opened {
      transform: translateX(-100%);
    }

    .menu-item {
      padding-right: 11px;

      > a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 15px;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  &__catalog-link {
    display: block;
    padding: 12px 20px;
    font-size: 15px;
    line-height: 20px;
    color: #000 !important;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }
  }

  &__search {
    position: relative;

    &-input {
      height: 54px;
      padding: 0 20px;
      background: #F8F8F8;
    }

    .form-label {
      left: 20px;
    }
  }

  &__city {
    padding: 10px 20px;

    &-value {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__actions {
    border-bottom: 1px solid $border;

    .header-action {
      height: 52px;

      &:not(:last-child) {
        border-right: 1px solid $border;
      }
    }
  }

  &__menu {
    list-style: none;

    > .menu-item {
      > a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 15px;
        color: #000;
        font-size: 14px;
        line-height: 22px;

        .menu-item__arrow {
          fill: #929292;
        }
      }
    }
  }

  &__contact {
    padding: 16px 34px;
    margin: 0 !important;

    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }
  }

  &.opened {
    pointer-events: all;
    overflow-x: hidden;

    #{$this}__wrap {
      transform: translateX(-100%);
    }
  }

  .opened--sub-menu {
    height: 100%;
    overflow: hidden;
  }

  &__feedback {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px dashed #000;
    width: fit-content;
    margin: 12px 14px 0;
    padding-bottom: 1px;
    cursor: pointer;
  }

  &-socials {
    padding: 11px 12px 13px;
    margin-top: 20px;
    border-bottom: 1px solid #DFDFDF;
    border-top: 1px solid #DFDFDF;

    &-item {
      margin: 3px;
    }
  }

  &-search {
    padding: 20px 15px;

    &__wrapper {
      border: 1px solid #BBBBBB;
      border-radius: 8px;
      padding: 10px 30px;
      display: flex;
      align-items: center;
    }

    &__input {
      border: none;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #A4A4A4;
      flex-grow: 1;
    }

    button {
      border: none;
      background: none;
      width: 34px;
      height: 34px;
      background: #FFD300;
      border-radius: 8px;
      svg {
        width: 15px;
        height: 15px;
        fill: #000;
      }
    }
  }
}

.menu-item__arrow {
  width: 10px;
  height: 6px;
  flex: 0 0 auto;
  margin-left: 10px;
  transform: rotate(-90deg);

  &--to-left {
    margin-left: 0;
    transform: rotate(90deg);
  }
}

@media (min-width: 1200px) {
  .mobile-menu {
    display: none;
  }
}