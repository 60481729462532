
@import 'base/mixins';

.tabs{

	position: relative;

	&__btn{

		padding-bottom: 19px;

		font-size: 20px;
		line-height: 26px;
		
		font-weight: 700;
		cursor: pointer;

		&:not(:last-child){

			margin-right: 61px;

		}

		@include _max {
			
			font-size: calcFluid(13,20);
			line-height: calcFluid(17,26);
			
		}

	}

	&__bar{

		width: 100px;
		height: 5px;
		position: absolute;
		bottom: 0;
		left: 0;
		background: var(--main);
		border-radius: 2px;
		transform-origin: left top;
		transition: transform $tr-time*2;

	}

	&-wrap{

		border-bottom: 1px solid $border;

	}

}

.tab{

	&:not(.active){
		display: none;
	}

}

.block-tab{

	display: flex;
	flex-wrap: wrap;

	margin: 0 -6px;

}

.tab-item{

	$this:&;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	width: fit-content;
	padding: 7 18px;

	border: 1px solid #EAEAEA;
	border-radius: 4px;

	margin: 0 6px;
	margin-bottom: 10px;

	transition: border ease 400ms;

	&:hover{

		border-color: var(--main);

		#{$this}{

			&__title{
				color: var(--main);
			}
	
			&__count{
	
			}
		}

	}

	&_single{

		#{$this}{

			&__title{

				margin-right: 0px;

			}
	
		}

	}

	&_active{

		border-color: var(--main);
		background-color: var(--main);

		#{$this}{

			&__title{

				color: #fff !important;

			}
	
		}

	}

	&_more{

		display: none;

		background-color: #EAEAEA;
		border: 1px solid #929292;

		#{$this}{

			&__title{

				color: #929292;

			}

		}

		&.active{

			visibility: visible;

		}

	}

	&__title{

		display: block;

		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 20px;

		color: #333333;

		margin-right: 8px;

		transition: color ease 400ms;


	}

	&__count{

		display: block;

		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;

		color: #B6B6B6;

	}

}

.block-tab-sort{
	display: flex;
	&__title{

		display: flex;
		align-items: center;

		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 20px;

		transition: all ease 400ms;

		cursor: pointer;

		// margin-right: 21px;

		color: #929292;

		&-svg{

			width: 10px;
			height: 6px;
			margin-left: 9px;
			fill:  #929292;

			transition: all ease 400ms;

			@include _large_ {

				display: none;
				
			}

		}

		&_active{

			svg{
				transform: rotate(180deg);
			}

		}

		@include _large_ {

			pointer-events: none;

			font-size: calcFluid(13,15,0,991,1920);
			margin-right: calcFluid(10,21,0,991,1920);
			
		}
		
	}

	&__grid{
		display: flex;
		// margin: 0 -13px;
	}

	&__filter{

		display: flex;
		margin-bottom: 17px;

		@include _large_ {

			display: none;

		}

		& > * {

			&:first-child{
				margin-right: 20px;
			}

		}

		&-btn{

			min-width: 157px;
    		height: 54px;

		}

	}

	&__count{

		margin: auto 0;

		font-style: normal;
		font-weight: bold;

		color: #B6B6B6;

		font-size: 14px;
		line-height: 20px;

	}

}

.tab-item-sort{

	$this:&;

	display: flex;
	align-items: center;

	width: fit-content;
	
	border: 1px solid transparent;

	cursor: pointer;

	padding: 0 calcFluid(8,13);

	transition: border ease 400ms;

	&__title{

		cursor: pointer;
		
		&__input{
			display: none;  
		}
	}

	&:hover{

		#{$this}{

			&__title{

				color: var(--main);

				&__input{
					display: none;  
				}
			}
		
		}

	}

	&_active{

		border: 1px solid var(--main);
		border-radius: 4px;

		#{$this}{

			&__title{

				color: var(--main);

			}
	
		}

	}

	&__title{

		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 22px;

		color: #B6B6B6;

		transition: color ease 400ms;

		@include _large_ {

			font-size: calcFluid(11,15,0,990,1920);
			
		}

	}

	&__input{

		display: none;

	}

	&__svg{

		width: 12px;
		height: 8px;

		fill: #B6B6B6;

		margin-left: 5px;

	}

}

.tab-order{

	$this:&;

	z-index: 1;

	position: relative;
	overflow: hidden;

	border: 1px solid transparent;
	border-radius: 4px;

	padding: 15px 27px;

	cursor: pointer;

	transition: all ease 300ms;

	&:hover{

		border-color: var(--main);

	}

	&__cup{

		position: absolute;

		width: 100%;
		height: 100%;

		top: 0;
		left: 0;

		z-index: -1;

		background: #F4F4F4;

	}
	
	&__input{
		
		display: none;

		&:checked{

			& ~ #{$this}__cup {

				background-color: var(--main-lighter);
	
			}

			& ~ #{$this}__title {

				color: var(--main);

			}

		}

	}

	&.active{

		background-color: #EBF7F6;

	}

	&.active & {

		&__title{

			color: var(--main);
			
		}
		
	}

	&__info{

		display: flex;
		flex-direction: column;

	}

	&__title{

		font-style: normal;
		font-weight: normal;

		font-size: 16px;
		line-height: 24px;

	}

	&__subtitle{

		color: #929292;

		font-style: normal;
		font-weight: normal;

		font-size: 12px;
		line-height: 18px;

	}

}

.tab-pay{

	$this:&;

	position: relative;

	border: 1px solid #EAEAEA;
	border-radius: 4px;

	padding: 15px 0;
	padding-left: 35px;
	padding-right: 15px;

	cursor: pointer;

	transition: all ease 300ms;

	@include _small {
		
		padding: 15px;
		
	}

	&:hover{

		border-color: var(--main);

	}

	&__cup{

		position: absolute;

		width: 100%;
		height: 100%;

		top: 0;
		left: 0;

		z-index: -1;

	}
	
	&__input{
		
		display: none;

		&:checked{

			& ~ #{$this}__cup {

				background-color: var(--main-lighter);
	
			}

			& ~ #{$this}__title {

				color: var(--main);

			}

		}

	}

	&.disable{

		pointer-events: none;

		background-color: #F4F4F4;
		border: none;

	}

	&.disable & {

		&__title{

			color: #C4C4C4;
	
		}
	
		&__subtitle{
	
			color: #C4C4C4;
	
		}

	}

	&__info{

		max-width: 245px;

	}

	&__header{

		display: flex;
		align-items: center;
		justify-content: space-between;

		margin-bottom: 7px;

	}

	&__wrap{

		display: flex;
		height: fit-content;

	}

	&__icon{

		display: block;
		
		object-fit: cover;
		object-position: center;
		
		width: 100%;
		height: 100%;
		
		max-width: 100%;
		max-height: 100%;

		&:not(:last-child){

			margin-right: 12px;

		}

	}

	&__title{
		
		display: block;

		margin-right: 15px;

		font-style: normal;
		font-weight: bold;

		font-size: 18px;
		line-height: 26px;

		color: var(--main);

	}

	&__subtitle{

		color: #929292;

		font-style: normal;
		font-weight: normal;

		font-size: 14px;
		line-height: 18px;

	}

}