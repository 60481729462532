


.file-uploader{
	&__btn{
		cursor: pointer;
		display: inline-block;
		padding: 12px 25px;
		border: 1px solid #32B0A9;
		border-radius: 4px;
		@include _small{
            padding: 8px 15px;
        }
	}
	&__input{
		display: none;
	}
	&__label{
		display: flex;
		align-items: center;
		font-weight: 700;
		font-size: 15px;
		line-height: 22px;
		color: #32B0A9;
		svg{
			width: 22px;
			height: 22px;
			margin-right: 10px;
			stroke: #32b0a9;
			fill: transparent;
		}
	}
	&__result{
		&:not(:empty){margin: 12px 0 0;}
		display: grid;
		grid-template-columns: repeat(auto-fill, 85px);
		grid-column-gap: 5px;
		grid-row-gap: 5px;
	}
}

.grid-image{
	&__item{
		position: relative;
	}
	&__image{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&__image-wrap{
		width: 85px;
		height: 55px;
		filter: brightness(0.8);
	}
	&__del{
		cursor: pointer;
		position: absolute;
		width: 15px;
		height: 15px;
		right: 0;
		top: 0;
		background-color: #fff;
		border-radius: 0 0 0 5px;
		&::before, &::after{
			content: '';
			display: block;
			position: absolute;
			width: 2px;
			height: 12px;
			background-color: #000;
			left: 7px;
			top: 1px;
		}
		&::before{
			transform: rotate(45deg);
		}
		&::after{
			transform: rotate(-45deg);
		}
	}
}





.form{

	&-input-wrap{

		position: relative;

		// border: 1px solid $border;
		// border-radius: $radius;
		// overflow: hidden;

		&.error{

			.form-label{

				color: #ED6262;

			}

		}
	}
	&-text-input{

		display: block;

		height: 42px;
		width: 100%;

		padding: 0 15px;
		font-size: 15px;
		line-height: 1.33;

		border: 1px solid $border;
		border-radius: $radius;

		&:focus,&:not(:placeholder-shown):not(textarea){
			+.form-label{
				transform: translateY(-100%) scale(.75);
			}
		}
		&:not(.placeholder-active){
			&::placeholder{
				color: transparent;
			}
		}

		
		// &[name=phone]{

		// 	&:focus,&:valid{

		// 		+.form-label{
		// 			display: none;
		// 		}

		// 	}
			
		// }

	}
	&-wrap{
		position: relative;
		height: fit-content;
		&--bg{
			.preloader, .mail-sent{
				background-color: #fff;
			}
		}
	}
	&-hiding-wrap{
		&--hide{
			opacity: 0;
			visibility: hidden;
		}
	}
	&-elem-error{
		position: absolute;
		left: 0;
		top: calc(100% + 1px);
		// font-size: calcFluid(14,16);
		font-size: 10px;
		color: #ED6262;
		line-height: 1.2;
		&::first-letter{
			text-transform: capitalize;
		}
	}

}
.form-label{
	height: 1em;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 14px;
	margin: auto;
	color: #929292;
	font-size: 15px;
	line-height: 1;
	transform-origin: left;
    pointer-events: none;
    transition: transform $tr-time;
    &.active{
    	transform: translateY(-100%) scale(.75);
    }
}
.personal{
	margin-top: calcFluid(10,14);
	font-size: 12px;
	line-height: 16px;
	&__link{
		color: inherit;
		text-decoration: underline;
		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}
	&--centered{
		text-align: center;
	}
}
.preloader, .mail-sent {
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.preloader{
	&__figure{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width:57px;
		height:57px;
		margin: auto;
		&--s{
			transform: scale(0.5);
		}
		&--m{
			transform: scale(0.79);
		}
		&--l{
			transform: scale(1);
		}
	}
	&__item{
		position:absolute;
		width:13px;
		height:13px;
		background-color: var(--main);
		border-radius:9px;
		animation: bounce_circularG 1.1s infinite;
		&-1{
			left:0;
			top:23px;
			animation-delay:0.41s;
		}

		&-2{
			left:6px;
			top:6px;
			animation-delay:0.55s;
		}

		&-3{
			top:0;
			left:23px;
			animation-delay:0.69s;
		}

		&-4{
			right:6px;
			top:6px;
			animation-delay:0.83s;
		}

		&-5{
			right:0;
			top:23px;
			animation-delay:0.97s;
		}

		&-6{
			right:6px;
			bottom:6px;
			animation-delay:1.1s;
		}

		&-7{
			left:23px;
			bottom:0;
			animation-delay:1.24s;
		}

		&-8{
			left:6px;
			bottom:6px;
			animation-delay:1.38s;
		}
	}
}
@keyframes bounce_circularG{
	0%{
		transform:scale(1);
	}

	100%{
		transform:scale(.3);
	}
}
.mail-sent{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&__title{
		font-size: 28px;
		line-height: 1.25;
		text-align: center;
		font-weight: 500;
	}
	&__icon{
		width: 50px;
		height: 50px;
		margin-bottom: 5px;
		fill: var(--main);
	}
}



// Не Ильи

.form-request{

	$formRequest:& !global;

	&__wrapper{
	}

	&__item{
		p{
			display: none;// скрытие динамических плейсхолдеров при заказе
		}

		position: relative;
		border-radius: 4px;

		&:first-child{

			

		}

	}

	&__input{

		display: block;

		width: 100%;
		height: 44px;

		padding: 0 15px;
		font-size: 15px;
		line-height: 1.33;

		border-radius: 4px;
		border: 1px solid #eaeaea;

	}

	&__textarea{
		border-radius: 4px;
		border: 1px solid #eaeaea;
		font-size: 15px;
		line-height: 1.33;
		width: 100%;
		resize: none;
		height: 145px;
		padding: 15px;
		::-webkit-input-placeholder{
			color: #929292;
			font-size: 15px;
			line-height: 1;
		}
	}

	&__label{

		height: 1em;
		position: absolute;

		top: 0;
		bottom: 0;

		left: 14px;

		margin: auto;
		color: #929292;

		font-size: 15px;
		line-height: 1;

		transform-origin: left;
		pointer-events: none;
		transition: transform $tr-time;

		&.active{
			transform: translateY(-100%) scale(.75);
		}

	}

	&__submit{



	}

}

.form-1{

	$form:&;

	&_contact{

		#{$form}{

			&__title{

				margin-bottom: 12px;

			}

			&__subtitle{

				margin-bottom: 25px;

			}

			&__request{

				margin-bottom: 10px;

			}

			
		}

		#{$formRequest}{

			&__wrapper{

				margin-bottom: 23px;

			}

			&__submit{

				width: 100%;

			}

		}

		&-2{

			border: 1px solid #EAEAEA;
			border-radius: 4px;

			padding: 30px 50px;

			@include _small {

				padding: 20px;
				
			}

			#{$form}{

				&__title{

					margin-bottom: 12px;
	
				}

				&__wrapper{

					display: flex;
					justify-content: space-between;

					max-width: 100%;
					
					@include _Extra_large_ {

						align-items: center;
						
					}

					@include _Extra_large {
						
						width: 440px;
						
						flex-direction: column;
						margin: auto;
						
					}

				}

				&__header{

					
					@include _Extra_large_ {

						width: 360px;
						margin-right: 10px;
						
					}

				}

				&__personal{

					width: 270px;

					@include _small {
						
						width: 100%;
						
					}

					@include _Extra_large_ {

						@include _max {
							
							width: calcFluid(200,270,0,1200,1920);

						}

						
					}

				}

				@include _Extra_large {
					
					&__title{

						margin-bottom: 12px;
		
					}
		
					&__subtitle{
		
						margin-bottom: 25px;
		
					}
		
					&__request{
		
						margin-bottom: 10px;
		
					}
		
					&__wrapper{
		
						margin-bottom: 23px;
		
					}
					
				}

			}

			#{$formRequest}{

				display: flex;
				
				@include _Extra_large_ {
					
					width: 510px;
					
				}

				@include _small {
					
					flex-direction: column;

					&__wrapper{

						margin-bottom: 23px;
		
					}
					
				}

				&__wrapper{

					margin-right: 27px;

					width: 250px;
					
					@include _Extra_large_ {

						@include _max {
							
							width: calcFluid(180,250,0,1200,1920);

						}
						
						
					}

				}

				&__submit{

					
					@include _Extra_large_ {

						width: 235px;
						
					}

				}

				@include _Extra_large {

					& > * {

						width: 100%;

					}
					
				}
		
			}

		}

		&-max{

			width: 100%;

		}

		&-fix{

			width: 416px;

		}


	}

	&__title{

		font-style: normal;
		font-weight: bold;

		font-size: 22px;
		line-height: 26px;

	}

	&__subtitle{

		font-style: normal;
		font-weight: normal;

		font-size: 16px;
		line-height: 24px;

		color: #929292;

	}

	&__personal{

		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;

		color: #929292;

	}

	&__link{

		text-decoration-line: underline;
		color: #929292;

	}

	&__request{

		#{$formRequest}{

			&__input{

				

			}

			&__submit{

				height: 44px;

			}
	
		}

	}

}

.form-personal{

	&__wrapper{

		margin-bottom: 20px;

	}

	&__submit{

		display: flex;
		justify-content: center;
		align-items: center;

		font-style: normal;
		font-weight: bold;

		font-size: 15px;
		line-height: 22px;

		width: 100%;
		height: 44px;

	}

	&__personal{

		display: block;

		margin-top: 10px;

		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;

		color: #AAAAAA;

		&-link{

			color: #AAAAAA;
			text-decoration: underline;

		}

	}

	&__control{

		display: flex;
		justify-content: space-between;

		margin-top: 10px;

		a{

			color: #929292;

			&:hover{

				color: var(--main);

			}

			font-weight: bold;
			font-size: 13px;
			line-height: 22px;

		}

	}

	&-item{

		&:not(:last-child){

			margin-bottom: 15px;

		}

	}

	.recaptcha{

		margin-top: 20px;

	}

}

.form-2{

	&__wrapper{

		display: flex;

	}

	&-item{

		$this:&;

		position: relative;

		&_textarea{

			.form-label{
				
				// display: flex;
				display: none; // скрытие динамических плейсхолдеров при заказе
				align-items: start;

				left: 15px;
				bottom: auto;

				height: 44px;

			}

			.form-text-input{

				padding-top: 15px;

				&:focus+.form-label,&:not(:placeholder-shown)+.form-label{

					transform: translateY(-32%) scale(.75);
	
				}

			}

		}

		&__title{

			display: block;

			font-style: normal;
			font-weight: normal;

			font-size: 15px;
			line-height: 20px;

			margin-bottom: 8px;

		}

		&__warning{

			display: block;

			position: absolute;

			transform: translateY(-100%);

			opacity: 0;
			visibility: hidden;

			font-style: normal;
			font-weight: normal;

			font-size: 12px;
			line-height: 18px;

		}

		&__svg{

			position: absolute;

			width: 14px;
			height: 14px;

			top: 50%;
			right: 12px;
			transform: translateY(-50%);

			opacity: 0;
			visibility: hidden;

			cursor: pointer;

		}

		&__svg-clarity{

			position: absolute;

			width: 20px;
			height: 20px;

			right: 15px;

			top: 21px;
			transform: translateY(-50%);

			path{

				fill: #717171;

			}

		}

		#{$formRequest}{

			&__label{
	
				color: #B6B6B6;
	
			}
	
			&__input{
	
				min-height: 44px;
	
			}
	
		}

		textarea{

			width: 100% !important;

		}

		&.error{

			margin-bottom: 30px;

			#{$this}{
	
				&__title{

					color: #ED6262;

				}

				&__warning{

					color: #ED6262;

				}

				&__svg{

					opacity: 1;
					visibility: visible;

					fill: #ED6262;

				}

				&__warning{

					color: #ED6262;

					transform: none;

					opacity: 1;
					visibility: visible;

				}		
		
			}
	
			#{$formRequest}{
	
				&__label{

	
				}
	
				&__item{
		
					border-color: #ED6262;
		
				}
		
			}
		
	
		}
	
	}
	
}


.lg-form{
	position: relative;
	padding: 48px 0;
	&__bg{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		filter: brightness(50%);
		object-fit: cover;
	}
	&__content{
		position: relative;
		z-index: 1;
		max-width: 500px;
		margin: auto;
		padding: 0 15px;
		&--title{
			@include font-h2;
			text-align: center;
			color: #FFFFFF;
			padding: 0 0 9px;
		}
		&--desc{
			@include font-p;
			text-align: center;
			color: #FFFFFF;
		}
		.form-wrap{
			width: 370px;
			margin: 18px auto 0;
			text-align: center;
		}
	}
	&__input{
		background-color: transparent;
		border: 1px solid #FFFFFF;
		border-radius: 5px;
		height: 55px;
		width: 100%;
		position: relative;
		margin: 0 0 14px;
		font-size: 16px;
		line-height: 24px;
		color: #FFFFFF;
		padding: 15px 20px;
		&::placeholder{
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
		}
	}
	&__submit{
		background: var(--main);
		border-radius: 5px;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #FFFFFF;
		height: 55px;
		width: 100%;
		margin: 0 0 8px;
		border: none;
		cursor: pointer;
	}
	&__personal{
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		color: rgba(255, 255, 255, 0.5);
		&--link{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}

	.preloader__item{background-color: #fff;}
	.mail-sent__title{color: #fff;}
	.mail-sent__icon{fill: #fff;}
}
@media (max-width: 490px){
	.lg-form__content .form-wrap{
		width: 100%;
	}
}