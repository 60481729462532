.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  &-body {
    &__title {
      font-weight: 900;
      font-size: calcFluid(15, 40);
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #000;
      margin-bottom: 10px;
      text-align: center;
    }

    &__subtitle {
      font-weight: 400;
      font-size: calcFluid(12, 16);
      color: #000;
      margin-bottom: calcFluid(20, 60);
      text-align: center;
    }

    .ps-btn {
      width: 100%;
      max-width: 100%;
    }

  }

  &.is-open {
    display: block;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .7);
    overflow: auto;
  }

  &__close {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
    z-index: 99;

    svg {
      display: block;
      width: 14px;
      height: 14px;
      fill: $text;
      pointer-events: none;
    }

    &:hover {
      svg {
        fill: var(--main);
      }
    }
  }

  &__container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: #ffffff;
    border-radius: 5px;
    overflow: hidden;
  }

  &__personal {
    margin-top: 10px;
    color: #c0c0c0;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.2;
  }

  &--default-animation {
    &[aria-hidden="false"] {
      .modal {
        &__overlay {
          animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
        }

        &__container {
          animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
        }
      }
    }

    &[aria-hidden="true"] {
      .modal {
        &__overlay {
          animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
        }

        &__container {
          animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
        }
      }
    }
  }

  &--alt-animation {
    &[aria-hidden="false"] {
      .modal {
        &__overlay {
          animation: mmfadeIn $tr-time cubic-bezier(0.0, 0.0, 0.2, 1);
        }
      }

      .modal-container {
        animation: slideFromRight $tr-time cubic-bezier(0.0, 0.0, 0.2, 1);
      }
    }

    &[aria-hidden="true"] {
      .modal {
        &__overlay {
          animation: mmfadeOut $tr-time cubic-bezier(0.0, 0.0, 0.2, 1);
        }
      }

      .modal-container {
        animation: slideToRight $tr-time cubic-bezier(0.0, 0.0, 0.2, 1);
      }
    }
  }
}

.modal-buy {

  z-index: 11;

  &__container {

    max-width: 490px;
    padding: calcFluid(36, 60) calcFluid(25, 66);

  }

  &__btn {

    width: 100%;
    height: 42px;

  }

  .form-2-item {

    margin-bottom: 20px;

  }

}

.personal-data {
  text-align: center;

  span {
    font-size: 14px;
    @media (max-width: 900px) {
      font-size: 12px;
    }

    a {
      cursor: pointer;
      font-size: 14px;
      color: var(--main);
      border-bottom: 1px solid var(--main);

      &:hover {
        border: none;
      }

      @media (max-width: 900px) {
        font-size: 12px;
      }
    }
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(2%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(2%);
  }
}

@media (max-width: 575px) {
  .modal {
    &__container {
      width: 90%;
    }

    &__close {
      top: 15px;
      right: 15px;
    }
  }
}

.modal-callback {
  .modal {
    &__container {
      max-width: 490px;
    }
    &-body {
      &__title {
        font-size: calcFluid(20, 42);
      }
      &__subtitle {
        margin-bottom: calcFluid(18, 36);
      }
      .ps-input {
        max-width: 100%;
        margin-bottom: 12px;
        color: #000;
      }
    }
    &-footer {
      margin-top: 14px;
      font-size: calcFluid(12, 15);
      text-align: center;
      color: #5A5A5A;
      a {
        color: #000;
        text-decoration: underline;
        transition: .3s;
        &:hover {
          transition: .15s;
        }
      }
    }
  }
}