.section-about {
  background: #F5F5F5;
  padding: calcFluid(15, 72) 0 calcFluid(40, 80);
  @include _medium {
    padding-bottom: 32px;
  }

  &-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include _mobile {
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    font-weight: 900;
    font-size: calcFluid(22, 54);
    color: #000;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    @include _medium {
      text-align: center;
      margin-top: 18px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: calcFluid(12, 18);
    color: #000;
    margin-top: calcFluid(15, 20);
    line-height: calcFluid(15, 24);
    margin-left: calcFluid(0, 5);

    p:not(:last-child) {
      margin-bottom: calcFluid(17, 23);
    }
  }



  &-info {
    width: 100%;
    max-width: 675px;
  }

  &-slider {
    max-width: 870px;
    flex-grow: 1;
    //padding: 50px 0 50px;
    min-height: 200px;
    margin: auto 0;
    @include _mobile {
      padding: calcFluid(0, 50, 0, 320, 1280) 0 calcFluid(15, 50, 0, 320, 1280);
    }
    @include _medium {
      width: 100%;
      min-height: auto;
      border-radius: 10px;
      img {
        border-radius: inherit;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .swiper {
      &-slide {
        img {
          margin: 0 auto;
        }
      }
    }
  }
}