.header-catalog-btn{
	&:hover{
		&~.catalog-menu{
			display: flex;
		}
	}
}

.catalog-menu{
	display: none;
	flex-direction: row;
	position: absolute;
	left: 0;
	top: calc(100% - 1px);
	width: 100%;
	&:hover{
		display: flex;
	}
	&-wrap{
		position: relative;
	}
	&__arr{
		transform: rotate(270deg);
		width: 12px;
		height: 8px;
		position: absolute;
		right: 15px;
		top: 0;
		bottom: 0;
		margin: auto;
		transition: $tr-time;
	}
	&__left{
		min-width: 305px;
		max-width: 305px;
		background-color: #fff;
		border: 1px solid #EAEAEA;
	}
	&__item{
		display: flex;
		padding: 15px 40px 15px 25px;
		font-size: 16px;
		line-height: 24px;
		color: #333333;
		transition: $tr-time;
		position: relative;
		&:hover{
			background-color: var(--main);
			color: #fff;
			svg{
				fill: #fff;
			}
		}
	}
	&__right{
		flex: 1 auto;
		border: 1px solid #EAEAEA;
		background-color: #fff;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 30px;
		grid-row-gap: 30px;
		grid-auto-rows: minmax(min-content,max-content);
		padding: 50px 90px;
		filter: drop-shadow(0px 14px 17px rgba(0, 0, 0, 0.04));
		margin-left: -1px;
		display: none;
		&.active{
			display: grid;
		}
	}
	&__subitem{
		&-title{
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
			color: #333333;
			margin: 0 0 15px;
			display: inline-block;
		}
		&-list{
			display: flex;
			flex-direction: column;
			padding: 0 0 0 20px;
			a{
				font-size: 14px;
				line-height: 20px;
				color: #929292;
				&:not(:last-child){
					margin: 0 0 10px;
				}
			}
		}
	}
	&__subitems{
		&__show-all{
			cursor: pointer;
			&__text{
				font-size: 14px;
				line-height: 20px;
				color: var(--main);
				border-bottom: 1px dashed var(--main); 
			}
		}
	}
}