.news-block {
  display: block;
  position: relative;
  border-radius: $radius;
  transition: box-shadow $tr-time;
  overflow: hidden;

  &__img {
    width: 100%;
    padding-bottom: 62%;
    border-radius: $radius;
    overflow: hidden;
    transition: border-radius $tr-time;
  }

  &__info {
    display: block;
    padding: 17px 17px 22px;
  }

  &__date {
    display: block;
    font-size: 12px;
    color: #929292;

    &--bottom-offset {
      margin-bottom: 12px;
    }

    &--top-offset {
      margin-top: 18px;
    }
  }

  &__title {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    transition: .2s;
  }

  &__text {
    display: block;
    margin-top: 25px;
    font-size: 15px;
    line-height: 20px;
    color: #929292;
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 62%;
    z-index: 1;
    opacity: 0;
    transition: opacity $tr-time;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: var(--main);
      opacity: 0.3;
    }

    &-btn {
      width: 125px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      margin: auto;
    }
  }

  &__cup {

    position: absolute;
    display: flex;

    width: 100%;
    height: 100%;

    background-color: #F1F7FA;

    svg {

      margin: auto;

    }

  }

  $this: &;

  &:hover {
    color: $text;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

    #{$this}__img {
      border-radius: $radius $radius 0 0;
    }

    #{$this}__cover {
      opacity: 1;
    }
  }
}

.news-grid {

  &__grid {

    height: fit-content;

  }

  &__pagination {

    margin: auto;
    margin-top: 20px;

  }

}

.news-all {
  padding: calcFluid(15, 30) 0 0;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: calcFluid(40, 55);
    background: var(--main);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF !important;
    margin: auto;
    transition: .2s;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
}

@media (min-width: 1200px) {
  .news-grid {
    overflow: visible;

    &__wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: #{$gap*2};
      row-gap: #{$gap*2};
      box-sizing: border-box;

      &--wide-columns {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &__pagination {
      // display: none;
    }
  }
}

@media (max-width: 1199px) {
  .news-grid {
    margin: 0 -#{$containerPadding};
    padding: 0 $containerPadding;

    &__slide {
      margin-bottom: 18px;
    }
  }
  .news-block {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  }
}


.section-blog-nav {
  &__wrapper {
    margin-top: 40px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    padding: calcFluid(0, 27) calcFluid(0, 27) 0;
    @include _medium {
      border: none;
    }
  }

  &-items {
    display: flex;
    margin: calcFluid(-12, -20);
    padding: 0 calcFluid(0, 100);
    flex-wrap: wrap;
  }

  &-item {
    $item: &;
    cursor: pointer;
    user-select: none;

    input {
      display: none;
    }

    input:checked + #{$item}__value, &:hover input + #{$item}__value {
      border-bottom-color: $primary;
      transition: .15s;
    }

    &__value {
      margin: calcFluid(12, 20);
      font-weight: 400;
      font-size: calcFluid(12, 18);
      padding-bottom: calcFluid(2, 20);
      border-bottom: 6px solid transparent;
      display: block;
      transition: .3s;
    }
  }
}