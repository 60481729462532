.seo-block{
    align-items: flex-start;
    &__content,
    &__image{
        max-height: calcFluid(250, 390);
        height: calcFluid(250, 390);
    }
    &__content{
        flex: 0 0 50%;
        max-width: 50%;
    }
    &__image{
        width: 100%;
        height: 100%;
        max-width: 47%;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__img{
        max-width: 48%;
    }
}

@media (min-width: 574px){
    .seo-block{
        &__img{
            &--content{
                display: none !important;
            }
        }
        &__content-text{
            padding-right: 36px;
        }
    }
}
@media (max-width: 573px){
    .seo-block{
        &__img{
            display: none;
        }
        &__content{
            flex: 0 1 100%;
        }
        &__content-text{
            padding-right: 15px;
        }
    }
}
@media (max-width: 567px){
    .content-seo{
        flex-direction: column;
        align-items: center;
    }
    .seo-block__content{
        
    }
    .seo-block__content,
    .seo-block__image{
        width: 100%;
        max-width: unset;
        margin: 10px 0;
    }

}