.index-products {
  &__tabs {
    position: relative;

    &-container {
      position: relative;
    }
  }

  &__to-all {
    position: absolute;
    right: 0;
    top: 5px;
  }

  &__tab {
    padding-top: calcFluid(24, 30);

    &:not(.active) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      opacity: 0;
      pointer-events: none;
    }
  }
}

.products-slider {
  position: relative;

  &__slide {
    display: flex;
    height: auto;
    padding: 0 0 60px;
  }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;

    &--prev {
      left: -15px;
    }

    &--next {
      right: -15px;
    }
  }

  &__container {
    height: fit-content;
  }

  &__pagination {
    margin-top: -20px;
    position: relative;
    z-index: 1;
  }
}

@media (max-width: 1199px) {
  .index-products {
    &__tabs-wrap {
      margin: 0 -#{$containerPadding};
      padding: 0 $containerPadding;
    }

    &__tabs {
      justify-content: space-between;
    }

    &__to-all {
      display: none;
    }

    &__btn {
      margin: 0 !important;
    }
  }
  .products-slider {
    margin: 0 -#{$containerPadding};
    padding: 0 #{$containerPadding + 10px} 0 $containerPadding;
    overflow: hidden;

    &__slide {
      padding-bottom: 0;

      > .product-card {
        width: 100%;
      }
    }

    &__pagination {
      margin: 18px 0 0;
    }

    &__container {
      overflow: visible;
    }

    &__arrow {
      display: none;
    }
  }
}


.section-products {
  overflow: hidden;

  &-header {
    display: flex;
    justify-content: space-between;
    @include _mobile {
      flex-wrap: wrap;
    }

    &__image {
      display: none;
      width: 100%;
      margin-top: 22px;
      @include _medium {
        display: block;
        height: 225px;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    &-img {
      @include _medium {
        display: none;
      }
    }

    &__wrapper {
      max-width: 830px;
    }

    &__description {
      margin-top: 5px;
      font-weight: 400;
      font-size: calcFluid(12, 18);
      line-height: calcFluid(15, 30);
      color: #222222;
      @include _medium {
        margin-top: 13px;
      }
    }

    &__button {
      margin-top: calcFluid(18, 30);
      @include _medium {
        margin-left: auto;
        margin-top: 18px;
        margin-right: auto;
        width: 203px;
        font-size: 14px;
      }
    }
  }

  &-items {
    height: fit-content;
    overflow: hidden;
    padding-top: 34px;
    padding-bottom: 100px;
    @include _medium {
      overflow: unset;
      width: 240px;
      max-width: 240px;
      padding-top: 22px;
      padding-bottom: 10px;
    }
  }

  &-item {
    @include _medium {
      height: 100% !important;
    }
  }
}