.section-about {
  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calcFluid(18, 54);

    &__wrapper {
      background: #FFFFFF;
      border: 2px solid #8A8A8A;
      border-radius: 8px;
      display: flex;
      padding: calcFluid(10, 30) calcFluid(15, 54);
      flex-wrap: wrap;
      @include _medium {
        padding: 30px 10px 20px;
      }

      img {
        margin: 0 0 0 33px;
        width: 165px;
        height: 100%;
        @include _medium {
          height: 91px;
          margin: 32px auto 0;
        }
      }
    }

    &__text {
      font-size: calcFluid(18, 30);
      line-height: 125%;
      letter-spacing: 0.02em;

      @include _medium {
        text-align: center;
        width: 100%;
      }

      p, b {
        font-size: calcFluid(18, 30);
        line-height: 125%;
        letter-spacing: 0.02em;
      }
    }


  }
}