
.section-advantages {
  margin-top: calcFluid(34, 90);

  &-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: calcFluid(14, 30);
    margin-top: calcFluid(20, 40);
    @include _mobile {
      grid-template-columns: 1fr 1fr;
    }
    @include _medium {
      grid-template-columns: 1fr;
    }
  }

  &-item {
    padding: calcFluid(14, 30) calcFluid(13, 40);
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    
    &__title {
      font-weight: 700;
      font-size: calcFluid(14, 20);
      color: #000000;
      margin-bottom: 14px;
      @include _small {
        text-align: center;
      }
    }

    &__description {
      font-size: calcFluid(12, 18);
      line-height: calcFluid(15, 27);
      font-weight: 400;
    }

  }
}