.page-header {
  background-image: url("/static/images/page-header-bg.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  $this: &;

  .breadcrumbs {
    color: #FFF;
  }

  &::before {
    @include _medium {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: linear-gradient(0deg, #000 16.11%, rgba(0, 0, 0, .6) 62.57%, rgba(0, 0, 0, 0) 98.52%);
    }
  }

  &__big {

    #{$this}__wrapper {
      min-height: calcFluid(407, 695);
    }
  }

  &-info__wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1072px;
    @include _medium {
      justify-content: flex-end;
      align-items: center;
      margin-top: auto;
      flex-grow: 0;
      padding-bottom: 40px;
    }
  }

  &__wrapper {
    min-height: 375px;
    display: flex;
    flex-direction: column;
    padding-bottom: calcFluid(0, 60);
    position: relative;
    @include _medium {
      min-height: auto;
    }
  }

  &__title {
    color: #FFF;
    font-size: calcFluid(20, 62);
    font-weight: 900;
    letter-spacing: 0.02em;
    @include _medium {
      text-align: center;
    }
  }

  &__subtitle {
    color: #FFF;
    margin-top: calcFluid(14, 20);
    font-size: calcFluid(14, 18);
    max-width: 50%;
    line-height: 150%;
    @include _medium {
      max-width: 100%;
      text-align: center;
    }
  }

  &__actions {
    margin-top: calcFluid(18, 40);

    .ps-btn {
      min-width: 240px;
    }
  }
}