$green: #06C07E;
$dark-green: #0F8258;
$light-gray: #f6f6f6;
$border: #EAEAEA;
$radius: 4px;
$text: #333;
$error: #f00;
$tr-time: .35s;
$primary: #FFD300;
$dark-primary: #E1BA00;
$secondary: #FFAE00;
$blue: #3EB3E5;
$divider: 40px;

$gap: 16px;
$containerPadding: 15px;
$containerWidth: 1628px;
