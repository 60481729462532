.index-block {

  margin-bottom: calcFluid(35, 70);

  .block-title {

    font-size: calcFluid(20, 36);

  }

  h1 {

    font-size: calcFluid(20, 36) !important;

  }

}

.hidden {

  &--mobile {

    @include _small {

      display: none;

    }

  }

}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.page__title {
  font-size: calcFluid(20, 54);
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 900;
  margin-top: 10px;
  @include _small {
    text-align: center;
    width: 100%;
  }
  .marker {
    background-color: $primary;
    padding: 0 calcFluid(5, 15);
    border-radius: 8px;
  }
}

.page__description {
  max-width: 1090px;
  margin-top: 18px;
  font-weight: 400;
  font-size: calcFluid(14, 18);
  color: #000;
}

.page__next {
  font-weight: 400;
  font-size: calcFluid(12, 18);
  color: #000;
  flex-shrink: 0;
  margin-top: 10px;
  @include _small {
    margin: 15px auto 10px;
  }

  svg {
    max-width: calcFluid(12, 16);
    max-height: calcFluid(12, 16);
    margin-left: calcFluid(13, 22);

    &.turn {
      transform: rotate(180deg);
    }
  }
}