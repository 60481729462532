.section-clients {

  &-items {
    margin-top: calcFluid(19, 25);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    @include _medium {
      grid-template-columns: repeat(4, 1fr);
      padding: 0 17px;
    }
    @include _small {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-item {
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    padding: 15px;
    @include _small {
      height: 55px;
      padding: 5px 15px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      filter: grayscale(100%);
      transition: .3s;
    }

    &:hover {
      img {
        filter: none;
        transition: .15s;
      }
    }
  }
}