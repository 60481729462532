.section-feedback {
  background-color: #F5F5F5;
  padding-left: calcFluid(50, 190, 0, 1280, 1920);
  padding-top: calcFluid(24, 64);
  padding-bottom: 35px;
  @include _mobile {
    padding-left: calcFluid(0, 190);
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    @include _mobile {
      flex-wrap: wrap;
    }
    @include _mobile {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &-body {
    max-width: 680px;
    padding-right: 15px;
    @include _mobile {
      padding-right: 0;
    }
  }

  &__title {
    font-weight: 900;
    font-size: calcFluid(18, 42);
    line-height: calcFluid(22, 42);
    color: #000;
    letter-spacing: 0.02em;
    margin-top: 18px;
    @include _container {
      text-align: center;
    }

  }

  &__description {
    margin-top: calcFluid(14, 20);
    font-weight: 400;
    font-size: calcFluid(12, 18);
    color: #000;

    ul {
      margin-left: calcFluid(20, 27);
      list-style: none;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: calcFluid(10, 25);
    }

    ul li:before {
      content: "\2022";
      color: #FFAE00;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      @include _medium {
        margin-left: -13px;
      }
    }
  }

  &-chapter {
    margin-top: 10px;
    max-width: 680px;
    overflow: hidden;
    height: calcFluid(191, 430);
    width: 85%;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    @include _mobile {
      width: 100%;
    }
    @include _medium {
      border-radius: 0;
    }

    &__link {
      background: rgba(255, 255, 255, .8);
      backdrop-filter: blur(14px);
      border-radius: 0 8px 0 0;
      padding: 10px 15px;
      font-size: calcFluid(12, 16);
      @include _medium {
        padding: 10px 15px 8px 18px;
      }

      svg {
        transform: rotate(180deg);
        width: calcFluid(4, 7);
        height: 14px;
        margin-left: calcFluid(5, 14);
      }
    }
  }

  .feedback-form {
    margin-top: calcFluid(10, 40);
    width: 100%;
    align-items: flex-start;

    &__row {
      width: 100%;

      input, button, div {
        flex-grow: 1;
        @include _container {
          // max-width: 100%;
          max-width: 98%;
          width: 100%;
        }
        &.min-width {
          @include _container {
            max-width: initial;
            width: initial;
          }
        }
      }

      input {
        color: #5E5E5E;
      }
    }

    &__footer {
      color: #5A5A5A;
      text-align: center;
    }
  }
}